/* eslint-disable no-param-reassign */
/* eslint-disable import/extensions */
/* eslint-disable no-plusplus */
/* eslint-disable eqeqeq */
import { useState, useEffect, useRef } from 'react';
import { HiUpload } from 'react-icons/hi';
import { withTranslation } from 'react-i18next';

import { IFilePickerProps, IFile } from './types';
import * as S from './Styles';
import EmptyPictureIcon from '../../../assets/iconComponents/EmptyPictureIcon';

const FilePicker = (props: IFilePickerProps) => {
  const [id, setId] = useState(new Date().getTime());
  const [files, setFiles] = useState<(IFile | string)[]>([]);
  const inputValueRef = useRef();
  const [usedInitialImagesAlready, setUsedInitialImagesAlready] =
    useState(false);

  useEffect(() => {
    props.handleChange(files);
  }, [files]);

  useEffect(() => {
    if (
      usedInitialImagesAlready !== true &&
      props.initialImages &&
      props.initialImages.length &&
      props.initialImages[0]
    ) {
      if (props.single != false) {
        if (props.initialImages[0]) {
          setFiles(props.initialImages);
          setUsedInitialImagesAlready(true);
        }
      } else {
        setFiles(props.initialImages);
        setUsedInitialImagesAlready(true);
      }
    }
  }, [props.initialImages]);

  const handleChange = (event: any) => {
    for (let i = 0; i < event.target.files.length; i++) {
      const file = event.target.files[i];
      const fileName = file.name;
      if (file.type.includes('image')) {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          if (props.single != false) {
            setFiles([
              { name: fileName, data: file, imageBase64: e.target.result },
            ]);
          } else {
            setFiles(prevFiles => [
              ...prevFiles,
              { name: fileName, data: file, imageBase64: e.target.result },
            ]);
          }
        };
        reader.readAsDataURL(file);
      }
    }
    if (props.single != false) {
      event.target.value = '';
    }
  };

  return (
    <S.FilePickerContainer
      marginTop={props.marginTop || '0px'}
      marginBottom={props.marginBottom || '0px'}
      marginLeft={props.marginLeft || '0px'}
      marginRight={props.marginRight || '0px'}
      paddingTop={props.paddingTop || '0px'}
      paddingBottom={props.paddingBottom || '0px'}
      paddingLeft={props.paddingLeft || '0px'}
      paddingRight={props.paddingRight || '0px'}
    >
      {props.type == 'images' && props.single != false && (
        <S.SingleImageContainer
          width={props.imageContainerWidth}
          height={props.imageContainerHeight}
        >
          {typeof files[0] === 'string' || files[0]?.imageBase64 ? (
            <S.SingleImage
              src={
                typeof files[0] === 'string' ? files[0] : files[0].imageBase64
              }
            />
          ) : (
            <S.EmptyPictureContainer>
              <EmptyPictureIcon width={'10rem'} height={'10rem'} />
            </S.EmptyPictureContainer>
          )}

          {(typeof files[0] === 'string' || files[0]?.imageBase64) && (
            <S.SingleImageCrossContainer onClick={() => setFiles([])}>
              <S.SingleImageCross />
            </S.SingleImageCrossContainer>
          )}
        </S.SingleImageContainer>
      )}

      <S.FilePickerButtonOuterContainer>
        <S.FilePickerButtonContainer>
          <S.FileInputLabel htmlFor={id} disabled={props.disabled || false}>
            <S.FileInput
              id={id}
              type="file"
              accept={props.fileType}
              multiple
              onChange={event => handleChange(event)}
              ref={inputValueRef}
            />
            <>
              <S.UploadImageIconContainer>
                <HiUpload size={'1.6rem'} />
              </S.UploadImageIconContainer>
              <S.FileInputText>{props.text}</S.FileInputText>
            </>
          </S.FileInputLabel>

          {/* {props.showSelectedFiles !== false && (
                        <S.FileNamesContainer>
                            {registeredFiles.map((file: { name: string }) => (
                                <S.FileNameContainer>
                                    <S.FileNameInnerContainer>
                                        {file.name}
                                        <S.CrossContainer>
                                            <FaTimes size={'0.7rem'} onClick={() => removeFile(file.name)} />
                                        </S.CrossContainer>
                                    </S.FileNameInnerContainer>
                                </S.FileNameContainer>
                            ))}
                        </S.FileNamesContainer>
                    )} */}
        </S.FilePickerButtonContainer>
      </S.FilePickerButtonOuterContainer>
    </S.FilePickerContainer>
  );
};

export default withTranslation()(FilePicker);
