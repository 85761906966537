/* eslint-disable eqeqeq */
/* eslint-disable import/extensions */
import { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTheme } from 'styled-components';

import StyledButton from '../../components/shared/Button/Button';
import * as S from './Styles';
import { IAdditionalDataStepProps } from './types';
import InputControlV2 from '../../components/shared/InputControlV2/InputControlV2';
import {
  ICountryComboBoxEntry,
  ISocialNetworkAccount,
  ISocialNetworkAccountSignUp,
  TCountriesAvailableValues,
  TTopicsInterestAvailableValues,
} from '../../Services/Utils/types';
import SocialNetworkAccountSelection from '../../components/shared/SocialNetworkAccountSelection/SocialNetworkAccountSelection';
import GenericModal from '../../components/shared/GenericModal/GenericModal';
import FilePickerV2 from '../../components/shared/FilePickerV2/FilePickerV2';
import { isAuthenticated } from '../../Services/Utils/Utils';
import {
  useGetAfterSignInData,
  useGetLocations,
  useGetToken,
  useGetTopicsInterest,
} from '../../Services/Utils/CustomHooks';
import LoaderLocalContainer from '../../components/shared/LoaderLocalContainer/LoaderLocalContainer';
import LoaderLocal from '../../components/shared/LoaderLocal/LoaderLocal';
import { IFile } from '../../components/shared/FilePickerV2/types';
import { useCompleteAdditionalDataStep } from './RQCustomHooks';
import NotifyCustom from '../../components/shared/NotifyCustom/NotifyCustom';
import InputSpinnerIcon from '../../assets/iconComponents/InputSpinnerIcon/InputSpinnerIcon';
import ComboBox from '../../components/shared/ComboBox/ComboBox';
import BackArrowIcon from '../../assets/iconComponents/BackArrowIcon';

const AdditionalDataStep = (props: IAdditionalDataStepProps) => {
  const [companyName, setCompanyName] = useState<string>('');
  const [companyWebsite, setCompanyWebsite] = useState<string>('');
  const [businessProfileName, setBusinessProfileName] = useState<string>('');
  const [businessProfileDescription, setBusinessProfileDescription] =
    useState<string>('');
  const [showAsteriskBusinessProfileName, setShowAsteriskBusinessProfileName] =
    useState<boolean>(false);
  const [files, setFiles] = useState<IFile[]>([]);
  const [socialNetworkAccounts, setSocialNetworkAccounts] = useState<
    Array<ISocialNetworkAccount>
  >([]);
  const [isGetAfterSignInDataEnabled, setIsGetAfterSignInDataEnabled] =
    useState<boolean>(false);
  const [isGetTokenEnabled, setIsGetTokenEnabled] = useState<boolean>(false);
  const [countriesAvailableValues, setCountriesAvailableValues] =
    useState<TCountriesAvailableValues>([]);
  const [isGetLocationsEnabled, setIsGetLocationsEnabled] =
    useState<boolean>(false);
  const [influencerName, setInfluencerName] = useState<string>('');
  const [countrySelected, setCountrySelected] = useState<
    ICountryComboBoxEntry | undefined
  >();
  const [hasError, setHasError] = useState<boolean>(false);
  const [msgError, setMsgError] = useState<string>('');
  const [showLoaderLocal, setShowLoaderLocal] = useState<boolean>(true);
  const navigate = useNavigate();
  const themeGlobal = useTheme();
  const {
    data: afterSignInData,
    isFetching: isFetchingGetAfterSignInData,
    isSuccess: isSuccessGetAfterSignInData,
    isError: isErrorGetAfterSignInData,
  } = useGetAfterSignInData(
    isGetAfterSignInDataEnabled,
    isAuthenticated()?.agentEmail ?? '',
  );
  const {
    isLoading: isLoadingCompleteAdditionalDataStep,
    isSuccess: isSuccessCompleteAdditionalDataStep,
    isError: isErrorCompleteAdditionalDataStep,
    mutate: mutateCompleteAdditionalDataStep,
  } = useCompleteAdditionalDataStep();
  const {
    isFetching: isFetchingGetToken,
    isSuccess: isSuccessGetToken,
    isError: isErrorGetToken,
  } = useGetToken(isGetTokenEnabled, isAuthenticated()?.agentEmail ?? '');
  const {
    data: locations,
    isFetching: isFetchingGetLocations,
    isSuccess: isSuccessGetLocations,
    isError: isErrorGetLocations,
  } = useGetLocations(isGetLocationsEnabled);

  useEffect(() => {
    if (!isAuthenticated()) {
      navigate('/');
    } else {
      setShowLoaderLocal(true);
      setIsGetAfterSignInDataEnabled(true);
    }
  }, []);

  useEffect(() => {
    if (
      isFetchingGetAfterSignInData ||
      isFetchingGetToken ||
      isFetchingGetLocations
    ) {
      setShowLoaderLocal(true);
    }
  }, [
    isFetchingGetAfterSignInData,
    isFetchingGetToken,
    isFetchingGetLocations,
  ]);

  useEffect(() => {
    if (isSuccessGetAfterSignInData && !isFetchingGetAfterSignInData) {
      setIsGetAfterSignInDataEnabled(false);
      if (!afterSignInData.isEmailVerified) {
        navigate('/account-activation');
      } else if (afterSignInData.isAdditionalDataStepComplete) {
        if (isAuthenticated()!.partnerType == 'INFLUENCER')
          navigate('/discover');
        else if (afterSignInData.isOperatorOrPartnerAdmin) navigate('/sbrand');
        else navigate('/cockpit');
      } else if (isAuthenticated()!.partnerType != 'INFLUENCER') {
        setCompanyWebsite(afterSignInData.partnerWebsite!);
        setShowLoaderLocal(false);
      } else {
        setIsGetLocationsEnabled(true);
      }
    }
  }, [isSuccessGetAfterSignInData, isFetchingGetAfterSignInData]);

  useEffect(() => {
    if (socialNetworkAccounts.length > 0 || businessProfileDescription) {
      setShowAsteriskBusinessProfileName(true);
    } else if (
      !(socialNetworkAccounts.length > 0) &&
      !businessProfileDescription
    ) {
      setShowAsteriskBusinessProfileName(false);
    }
  }, [socialNetworkAccounts.length, businessProfileDescription]);

  useEffect(() => {
    if (locations) {
      const countriesAvailableValues: TCountriesAvailableValues = [];
      locations.forEach((entry, key) => {
        countriesAvailableValues.push({
          value: key,
          label: entry.country,
          data: {
            country: entry.country,
            countryId: entry.countryId,
          },
        });
      });
      setCountriesAvailableValues(countriesAvailableValues);
      setShowLoaderLocal(false);
    }
  }, [isSuccessGetLocations]);

  useEffect(() => {
    if (isErrorGetAfterSignInData) {
      setHasError(true);
      setMsgError(props.t('signin.generic.couldNotGetEssentialData'));
      setIsGetAfterSignInDataEnabled(false);
    }
  }, [isErrorGetAfterSignInData]);

  useEffect(() => {
    if (isErrorGetLocations) {
      setHasError(true);
      setMsgError(props.t('signin.generic.couldNotGetEssentialData'));
      setIsGetLocationsEnabled(false);
    }
  }, [isErrorGetLocations]);

  useEffect(() => {
    if (isErrorCompleteAdditionalDataStep) {
      setHasError(true);
      setMsgError(props.t('additionalDataStep.couldNotUpdatePartner'));
    }
  }, [isErrorCompleteAdditionalDataStep]);

  useEffect(() => {
    if (isErrorGetToken) {
      setHasError(true);
      setMsgError(props.t('generic.couldNotUpdateToken'));
      setIsGetTokenEnabled(false);
    }
  }, [isErrorGetToken]);

  useEffect(() => {
    if (isSuccessCompleteAdditionalDataStep) {
      setIsGetTokenEnabled(true);
    }
  }, [isSuccessCompleteAdditionalDataStep]);

  useEffect(() => {
    if (isSuccessGetToken) {
      if (isAuthenticated()?.partnerType == 'INFLUENCER') navigate('/discover');
      else if (afterSignInData.isOperatorOrPartnerAdmin) navigate('/sbrand');
      else navigate('/cockpit');
    }
  }, [isSuccessGetToken]);

  const isContinueButtonDisabled = () => {
    const sessionData = isAuthenticated();
    if (sessionData?.partnerType == 'INFLUENCER') {
      if (
        !influencerName ||
        !countrySelected ||
        showLoaderLocal ||
        isLoadingCompleteAdditionalDataStep ||
        isFetchingGetAfterSignInData
      )
        return true;
      return false;
    }
    if (
      sessionData?.partnerType == 'BRAND' ||
      sessionData?.partnerType == 'AGENCY'
    ) {
      if (
        !companyName ||
        !companyWebsite ||
        (socialNetworkAccounts.length > 0 && !businessProfileName) ||
        (businessProfileDescription && !businessProfileName) ||
        isLoadingCompleteAdditionalDataStep ||
        isFetchingGetAfterSignInData ||
        showLoaderLocal
      )
        return true;
      return false;
    }
    return true;
  };

  const continueActionInvoked = () => {
    // let socialTokensLocal: ISocialNetworkAccountSignUp[] = []
    // socialTokensLocal = socialNetworkAccounts.map((sna: ISocialNetworkAccount) => ({
    //     type: sna.sn,
    //     name: sna.name,
    //     token: sna.token,
    //     profileId: sna.profileId,
    //     refreshToken: null,
    //     tokenSecret: null,
    //     displayPicture: sna.displayPicture,
    //     screenName: null,
    //     externalUrl: null,
    //     expired: sna.expired || false,
    //     apiMode: 'default',
    // }))
    let businessProfilePictureFormData;
    if (files[0]?.data) {
      businessProfilePictureFormData = new FormData();
      businessProfilePictureFormData.append('file', files[0]!.data);
      businessProfilePictureFormData.append(
        'source',
        'BUSINESS_PROFILE_PICTURE',
      );
    }
    mutateCompleteAdditionalDataStep({
      completeAdditionalDataStepParams: {
        companyName: companyName || '',
        companyWebsite: companyWebsite || '',
        businessProfileName: businessProfileName || '',
        businessProfileDescription: businessProfileDescription || '',
        businessProfilePictureUrl: '',
        influencerName,
        topicsOfInterest: [],
        countryCode: countrySelected?.value,
        countryId: countrySelected?.data.countryId,
        country: countrySelected?.data.country,
      },
      businessProfilePictureData: businessProfilePictureFormData,
    });
  };

  const onErrorModalCloseButtonClick = () => {
    if (isErrorGetAfterSignInData) {
      setIsGetAfterSignInDataEnabled(true);
    }
    if (isErrorGetLocations) {
      setIsGetLocationsEnabled(true);
    }
    if (isErrorGetToken) {
      setIsGetTokenEnabled(true);
    }
    setHasError(false);
  };

  const renderFormContent = () => {
    return (
      <S.InfluencerContainer>
        <LoaderLocal
          show={
            isFetchingGetAfterSignInData ||
            isFetchingGetLocations ||
            showLoaderLocal
          }
          message={props.t('generic.loading1')}
          borderRadius={'2.4rem'}
          spinner={true}
          spinnerFontSize={'1.7rem'}
        >
          <div
            style={{
              height: '69.6rem',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <S.HeaderOuterContainer>
              <S.HeaderContainer>
                <S.HeaderBackArrowContainer
                  onClick={() => {
                    sessionStorage.removeItem('jwt');
                    navigate('/');
                  }}
                >
                  <BackArrowIcon width={'2.8rem'} height={'2.8rem'} />
                </S.HeaderBackArrowContainer>
                <S.HeaderTitleContainer>
                  {props.t('additionalDataStep.beforeYouContinue')}
                </S.HeaderTitleContainer>
                <S.HiddenHeaderElement />
              </S.HeaderContainer>
              <S.HeaderDivider />
            </S.HeaderOuterContainer>

            <S.FormContainer>
              <S.InfluencerNameAndCountriesContainer>
                <S.InfluencerNameInputContainer>
                  <InputControlV2
                    value={influencerName}
                    onChange={(inputValue: string) =>
                      setInfluencerName(inputValue)
                    }
                    title={props.t('generic.name')}
                    inputFieldContainerWithTitleWidth={'100%'}
                    showMandatoryAsterisk={true}
                  />
                </S.InfluencerNameInputContainer>

                <S.InfluencerCountryContainer>
                  <S.TopicsInterestTitleContainer>
                    {props.t('generic.country')}
                  </S.TopicsInterestTitleContainer>

                  <ComboBox
                    valueKey={'countrySelected'}
                    value={countrySelected}
                    type={'single'}
                    width={'100%'}
                    borderRadius={'0.6rem'}
                    valuesAvailable={countriesAvailableValues}
                    isFilterable={true}
                    isClearable={true}
                    onChange={(obj: {
                      countrySelected: ICountryComboBoxEntry;
                    }) => {
                      setCountrySelected(obj.countrySelected);
                    }}
                    height={'4.4rem'}
                    availableOptionsContainerBorderRadius={'0.6rem'}
                    optionContainerBorderRadius={'0.6rem'}
                    innerOptionsContainerMarginTop={'1.2rem'}
                    innerOptionsContainerMarginBottom={'1.2rem'}
                    innerOptionsContainerPaddingLeft={'1.2rem'}
                    innerOptionsContainerPaddingRight={'1.2rem'}
                    optionContainerPaddingTop={'2rem'}
                    optionContainerPaddingBottom={'2rem'}
                    optionContainerPaddingLeft={'2rem'}
                    optionContainerPaddingRight={'2rem'}
                    themeStyles={(themeGlobal as any).comboBoxTopicsOfInterest}
                    // loading={countriesAvailableValues.length == 0}
                    changeBackgroundColorOnHover={false}
                    innerOptionsContainerMaxHeight={'25rem'}
                  />
                </S.InfluencerCountryContainer>
              </S.InfluencerNameAndCountriesContainer>
            </S.FormContainer>

            <S.FooterContainer>
              <S.ContinueButtonContainer>
                <StyledButton
                  borderRadius={'0.6rem'}
                  borderWidth={'0rem'}
                  paddingLeft={'2.5rem'}
                  paddingRight={'2.5rem'}
                  height={'4rem'}
                  fontSize={'1.6rem'}
                  display={'block'}
                  textAlign={'center'}
                  fontWeight={'500'}
                  cursorOnDisable={'default'}
                  blockHoverStylesOnDisable={true}
                  pointerEventsOnDisable={'none'}
                  disabledNotNative={isContinueButtonDisabled()}
                  onClick={() => continueActionInvoked()}
                >
                  {props.t('signin.generic.continue')}
                </StyledButton>
              </S.ContinueButtonContainer>
            </S.FooterContainer>
          </div>
        </LoaderLocal>
      </S.InfluencerContainer>
    );
  };

  return (
    <S.OuterContainer>
      {renderFormContent()}

      <NotifyCustom
        location={'bottom'}
        show={isLoadingCompleteAdditionalDataStep}
        themeStyles={(themeGlobal as any).signin['regular-notify']}
      >
        <S.NotifyInnerContainer>
          <InputSpinnerIcon
            containerWidth={'2rem'}
            containerHeight={'2rem'}
            containerMarginRight={'1rem'}
          />
          {props.t('additionalDataStep.updatingPartner')}
        </S.NotifyInnerContainer>
      </NotifyCustom>

      <GenericModal
        show={hasError}
        title={props.t('generic.uhoh')}
        body={msgError}
        primaryButtonText={props.t('generic.close')}
        primaryButtonAction={() => onErrorModalCloseButtonClick()}
      />
    </S.OuterContainer>
  );
};

export default withTranslation()(AdditionalDataStep);
