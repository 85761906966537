/* eslint-disable no-use-before-define */
import { AxiosInstance } from 'axios';
import queryString from 'query-string';

import config from '../../../config';
import {
  GetPublicPostsByPublicUserIdResponse,
  GetPublicUserHashtagsSortedByMetricsParams,
  GetPublicUserHashtagsSortedByMetricsResponse,
  PublicPostTypes,
  SortByCriteria,
  SortOrder,
} from './types';

const createPostInstanceApi = (instance: AxiosInstance) => {
  const getPublicUserHashtagsSortedByMetrics = async (
    data: GetPublicUserHashtagsSortedByMetricsParams,
  ) => {
    const query = queryString.stringify(
      {
        publicUserIds: [data.publicUserId],
        startDate:
          data.startDate ?? new Date().getTime() - 30 * 24 * 3600 * 1000,
        endDate: data.endDate ?? new Date().getTime(),
        sortBy: 'likes',
        limit: data.limit ?? 5,
        keywords: data.keywords ?? [],
        iPostIds: data.includePostIds ?? [],
        ePostIds: data.excludePostIds ?? [],
      },
      { arrayFormat: 'bracket' },
    );

    const url = `${config.ENDPOINTS.POST.GET_PUBLIC_USER_HASHTAGS_SORTED_BY_METRICS}?${query}`;

    const {
      data: hashtags,
    }: { data: GetPublicUserHashtagsSortedByMetricsResponse } =
      await instance.get(url);
    return hashtags[Object.keys(hashtags)[0]].map(item => item.hashtag);
  };

  const getPublicPostsByPublicUserId = async (
    data: IGetPublicPostsByPublicUserIdParams,
  ) => {
    const query = queryString.stringify(
      {
        publicUserIds: data.publicUserIds,
        startDate: data.startDate,
        endDate: data.endDate,
        types: data.types ?? [
          PublicPostTypes.IMAGE,
          PublicPostTypes.PHOTO,
          PublicPostTypes.TEXT,
          PublicPostTypes.VIDEO,
          PublicPostTypes.STORY,
        ],
        page: data.page ?? 1,
        limit: data.page ?? 9999999,
        keywords: data.keywords ?? [],
        iPostIds: data.includePostIds ?? [],
        ePostIds: data.excludePostIds ?? [],
      },
      { arrayFormat: 'bracket' },
    );

    const url = `${config.ENDPOINTS.POST.GET_PUBLIC_POSTS_BY_PUBLIC_USER_ID}?${query}`;

    const {
      data: publicPosts,
    }: { data: GetPublicPostsByPublicUserIdResponse } = await instance.get(url);

    return publicPosts;
  };

  return {
    getPublicUserHashtagsSortedByMetrics,
    getPublicPostsByPublicUserId,
  };
};

export default {
  createPostInstanceApi,
};

export interface IGetPublicPostsByPublicUserIdParams {
  publicUserIds: string[];
  startDate: number;
  endDate: number;
  types?: PublicPostTypes[];
  page?: number;
  sortBy?: Exclude<SortByCriteria, 'hashtag'>;
  sortOrder?: SortOrder;
  limit?: number;
  keywords?: string[];
  includePostIds?: string[];
  excludePostIds?: string[];
}
