/* eslint-disable no-use-before-define */
import { SocialNetworks } from '../../../Utils/types';

export type ExternalMediaKitConfiguration = {
  id: string;
  type?: ExternalMediaKitConfigurationTypes;
  name?: string;
  targetId?: string;
  displayPicture?: string;
  agentId?: string;
  userId?: string;
  expiredAt?: Date | null;
  socialNetwork?: SocialNetworks;
  insights?: {
    views: number;
  };
  userMkImages?: string[];
  filters?: {
    keywords?: string[] | null;
    includePostIds?: string[] | null;
    excludePostIds?: string[] | null;
  };
  details?: {
    startAt?: Date;
    endAt?: Date | null;
    picture?: string;
    kpis?: {
      audience?: {
        show?: boolean;
        avg?: boolean;
        total?: boolean;
        growth?: boolean;
      };
      engagementRate?: {
        show?: boolean;
        avg?: boolean;
        total?: boolean;
        growth?: boolean;
      };
      likes?: {
        show?: boolean;
        avg?: boolean;
        total?: boolean;
        growth?: boolean;
      };
      comments?: {
        show?: boolean;
        avg?: boolean;
        total?: boolean;
        growth?: boolean;
      };
      shares?: {
        show?: boolean;
        avg?: boolean;
        total?: boolean;
        growth?: boolean;
      };
      impressions?: {
        show?: boolean;
        avg?: boolean;
        total?: boolean;
        growth?: boolean;
      };
      reach?: {
        show?: boolean;
        avg?: boolean;
        total?: boolean;
        growth?: boolean;
      };
      posts?: {
        show?: boolean;
        avg?: boolean;
        total?: boolean;
        growth?: boolean;
      };
    };
    posts?: {
      show?: boolean;
      limit?: number;
      order?: PostsOrderByCriteria;
    };
    demographicsCountry?: {
      show?: boolean;
    };
    demographicsCity?: {
      show?: boolean;
    };
    genderAgeSplit?: {
      show?: boolean;
    };
    biography?: {
      show?: boolean;
    };
    hashtags?: {
      show?: boolean;
    };
    mediaKits?: { picture?: string }[];
  };
  createdAt?: Date;
  updatedAt?: Date;
  url?: string;
};

export type GetExternalMediaKitConfigurationsByUserIdResponse =
  ExternalMediaKitConfiguration[];

export enum ExternalMediaKitConfigurationTypes {
  EMK_PUBLIC_USER = 'EMK_PUBLIC_USER',
  EMK_SKORR_USER = 'EMK_SKORR_USER',
  EMK_PORTFOLIO = 'EMK_PORTFOLIO',
}

export enum PostsOrderByCriteria {
  DATE = 'DATE',
}
