/* eslint-disable no-undef */
/* eslint-disable import/extensions */
/* eslint-disable no-use-before-define */
/* eslint-disable consistent-return */
/* eslint-disable default-case */
/* eslint-disable no-plusplus */
/* eslint-disable eqeqeq */
import { useState, useEffect, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import ReactInputVerificationCode from 'react-input-verification-code';

import Button from '../../components/shared/Button/Button';
import * as S from './Styles';
import { IAccountActivationProps } from './types';
import SkorrOnboardingBackgroundImage from '../../assets/skorr-onboarding-bg.jpg';
import SkorrLogo from '../../assets/logo-skorr-vh.svg';
import SkorrLogoSmallWindow from '../../assets/logo-skorr-vh-noslogan.svg';
import InputControlV2 from '../../components/shared/InputControlV2/InputControlV2';
import { valueEmpty } from '../../validations/validationFunctions';
import GenericModal from '../../components/shared/GenericModal/GenericModal';
import NotifyCustom from '../../components/shared/NotifyCustom/NotifyCustom';
import InputSpinnerIcon from '../../assets/iconComponents/InputSpinnerIcon/InputSpinnerIcon';
import EnvelopeIcon from '../../assets/iconComponents/EnvelopeIcon';
import {
  useSendAccountActivationCodeEmail,
  useVerifyAccountActivationCode,
  useCertifyActivationCodeEmailSent,
} from './RQCustomHooks';
import BackArrowIcon from '../../assets/iconComponents/BackArrowIcon';
import { getThemeStyles } from '../../css/ThemeGlobal';
import InputCheckMarkIcon from '../../assets/iconComponents/InputCheckMarkIcon';
import { isAuthenticated } from '../../Services/Utils/Utils';
import { useGetAfterSignInData } from '../../Services/Utils/CustomHooks';
import LoaderLocal from '../../components/shared/LoaderLocal/LoaderLocal';

const AccountActivation = (props: IAccountActivationProps) => {
  const [verificationCode, setVerificationCode] = useState<string>('');
  const verificationCodeInputRef1 = useRef<HTMLInputElement>();
  const verificationCodeInputRef2 = useRef<HTMLInputElement>();
  const verificationCodeInputRef3 = useRef<HTMLInputElement>();
  const verificationCodeInputRef4 = useRef<HTMLInputElement>();
  const verificationCodeInputRef5 = useRef<HTMLInputElement>();
  const verificationCodeInputRef6 = useRef<HTMLInputElement>();
  const [numberOfSecondsRemaining, setNumberOfSecondsRemaining] =
    useState<Number>(0);
  const countDownTimerRefId = useRef<NodeJS.Timer>();
  const [showCodeSentToEmailNotification, setShowCodeSentToEmailNotification] =
    useState<boolean>(false);
  const [
    showAccoundActivatedNotification,
    setShowAccountActivatedNotification,
  ] = useState<boolean>(false);
  const [isGetAfterSignInDataEnabled, setIsGetAfterSignInDataEnabled] =
    useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [msgError, setMsgError] = useState<string>('');
  const [showLoaderLocal, setShowLoaderLocal] = useState<boolean>(true);
  const navigate = useNavigate();
  const themeGlobal = useTheme();
  const {
    data: afterSignInData,
    isFetching: isFetchingGetAfterSignInData,
    isSuccess: isSuccessGetAfterSignInData,
    isError: isErrorGetAfterSignInData,
    refetch: refetchSpecifier,
  } = useGetAfterSignInData(
    isGetAfterSignInDataEnabled,
    isAuthenticated()?.agentEmail ?? '',
  );
  const {
    isLoading: isLoadingSendAccountActivationCodeEmail,
    isSuccess: isSuccessSendAccountActivationCodeEmail,
    isError: isErrorSendAccountActivationCodeEmail,
    mutate: mutateSendAccountActivationCodeEmail,
  } = useSendAccountActivationCodeEmail(setHasError, setMsgError, props.t);
  const {
    isLoading: isLoadingVerifyAccountActivationCode,
    isSuccess: isSuccessVerifyAccountActivationCode,
    isError: isErrorVerifyAccountActivationCode,
    mutate: mutateVerifyAccountActivationCodeEmail,
  } = useVerifyAccountActivationCode();

  useEffect(() => {
    if (!isAuthenticated()) {
      navigate('/');
    } else {
      setIsGetAfterSignInDataEnabled(true);
      refetchSpecifier.refetchIsEmailVerified();
      refetchSpecifier.refetchIsAdditionalDataStepComplete();
      refetchSpecifier.refetchIsOperatorOrPartnerAdmin();
    }

    return () => {
      if (countDownTimerRefId.current) {
        clearInterval(countDownTimerRefId.current);
      }
    };
  }, []);

  useEffect(() => {
    if (isFetchingGetAfterSignInData) {
      setShowLoaderLocal(true);
    }
  }, [isFetchingGetAfterSignInData]);

  useEffect(() => {
    if (isSuccessGetAfterSignInData && !isFetchingGetAfterSignInData) {
      setIsGetAfterSignInDataEnabled(false);
      if (afterSignInData.isEmailVerified) {
        if (afterSignInData.isAdditionalDataStepComplete) {
          if (isAuthenticated()!.partnerType == 'INFLUENCER')
            navigate('/discover');
          else if (afterSignInData.isOperatorOrPartnerAdmin)
            navigate('/sbrand');
          else navigate('/cockpit');
        } else navigate('/additional-data-step');
      } else setShowLoaderLocal(false);
    }
  }, [isSuccessGetAfterSignInData, isFetchingGetAfterSignInData]);

  useEffect(() => {
    if (isSuccessSendAccountActivationCodeEmail) {
      setNumberOfSecondsRemaining(30);
      setShowCodeSentToEmailNotification(true);
      setTimeout(() => setShowCodeSentToEmailNotification(false), 4000);
    }
  }, [isSuccessSendAccountActivationCodeEmail]);

  useEffect(() => {
    if (isSuccessVerifyAccountActivationCode) {
      setShowAccountActivatedNotification(true);
      setTimeout(() => setShowAccountActivatedNotification(false), 2000);
      if (afterSignInData.isAdditionalDataStepComplete) {
        if (isAuthenticated()!.partnerType == 'INFLUENCER')
          navigate('/discover');
        else if (afterSignInData.isOperatorOrPartnerAdmin) navigate('/sbrand');
        else navigate('/cockpit');
      } else navigate('/additional-data-step');
    }
  }, [isSuccessVerifyAccountActivationCode]);

  useEffect(() => {
    if (numberOfSecondsRemaining == 30 && !countDownTimerRefId.current) {
      countDownTimerRefId.current = setInterval(() => {
        if ((numberOfSecondsRemaining as number) > 0) {
          setNumberOfSecondsRemaining(prevNumberOfSecondsRemaining => {
            return (prevNumberOfSecondsRemaining as number) - 1;
          });
        }
      }, 1200);
    }
  }, [numberOfSecondsRemaining]);

  useEffect(() => {
    if (countDownTimerRefId.current && numberOfSecondsRemaining == 0) {
      clearInterval(countDownTimerRefId.current);
      countDownTimerRefId.current = undefined;
    }
  }, [numberOfSecondsRemaining]);

  useEffect(() => {
    if (isErrorVerifyAccountActivationCode) {
      setHasError(true);
      setMsgError(props.t('signin.generic.couldNotActivateAccount'));
    }
  }, [isErrorVerifyAccountActivationCode]);

  useEffect(() => {
    if (isErrorGetAfterSignInData) {
      setHasError(true);
      setMsgError(props.t('signin.generic.couldNotGetEssentialData'));
      setIsGetAfterSignInDataEnabled(false);
    }
  }, [isErrorGetAfterSignInData]);

  const onPageContainerKeyDownHandler = async (event: any) => {
    if (event.keyCode == 86 && event.ctrlKey) {
      const clipboardContent = await navigator.clipboard.readText();
      if (typeof clipboardContent === 'string') {
        let verificationCodeLocal = '';
        let i = 0;
        for (; i < 6; i++) {
          if (clipboardContent[i]) {
            verificationCodeLocal = verificationCodeLocal.concat(
              clipboardContent[i],
            );
          } else break;
        }
        const j = i;
        if (i != 6) {
          for (; i < 6; i++) {
            verificationCodeLocal = verificationCodeLocal.concat(' ');
          }
          switch (j) {
            case 0:
              verificationCodeInputRef1.current?.focus();
              break;
            case 1:
              verificationCodeInputRef2.current?.focus();
              break;
            case 2:
              verificationCodeInputRef3.current?.focus();
              break;
            case 3:
              verificationCodeInputRef4.current?.focus();
              break;
            case 4:
              verificationCodeInputRef5.current?.focus();
              break;
            case 5:
              verificationCodeInputRef6.current?.focus();
              break;
          }
        } else verificationCodeInputRef6.current?.focus();
        setVerificationCode(verificationCodeLocal);
      }
    }
  };

  const verificationCodeInputChanged = (step: number, codeFragment: string) => {
    const verificationCodeArray: Array<string> = [];
    for (let i = 0; i < 6; i++) {
      if (verificationCode[i]) verificationCodeArray.push(verificationCode[i]);
    }
    for (let i = 0; i < 6; i++) {
      if (i == step - 1)
        verificationCodeArray[i] = codeFragment
          ? codeFragment[codeFragment.length - 1]
          : ' ';
    }
    let verificationCodeString = '';
    for (let i = 0; i < verificationCodeArray.length; i++) {
      verificationCodeString = verificationCodeString.concat(
        verificationCodeArray[i],
      );
    }
    setVerificationCode(verificationCodeString);
    if (!valueEmpty(codeFragment)) {
      switch (step) {
        case 1: {
          verificationCodeInputRef2.current?.focus();
          break;
        }
        case 2: {
          verificationCodeInputRef3.current?.focus();
          break;
        }
        case 3: {
          verificationCodeInputRef4.current?.focus();
          break;
        }
        case 4: {
          verificationCodeInputRef5.current?.focus();
          break;
        }
        case 5: {
          verificationCodeInputRef6.current?.focus();
          break;
        }
      }
    }
  };

  const getVerificationCodeSingleInput = (step: number) => {
    return (
      <InputControlV2
        value={verificationCode[step - 1] ? verificationCode[step - 1] : ''}
        onChange={(inputValue: string) =>
          verificationCodeInputChanged(step, inputValue || '')
        }
        inputFieldThemeStyles={getThemeStyles(
          ['inputVerificationCodeSingle'],
          themeGlobal,
        )}
        disabledNotNative={
          step != 1 &&
          valueEmpty(verificationCode[step - 2]) &&
          valueEmpty(verificationCode[step - 1])
        }
        refNotNative={step != 1 && getInputRefByStep(step)}
        focusOnMount={step == 1}
        inputFieldPaddingLeft={'1.6rem'}
        inputFieldPaddingRight={'1.6rem'}
      />
    );
  };

  const getInputRefByStep = (step: number) => {
    switch (step) {
      case 1:
        return verificationCodeInputRef1;
      case 2:
        return verificationCodeInputRef2;
      case 3:
        return verificationCodeInputRef3;
      case 4:
        return verificationCodeInputRef4;
      case 5:
        return verificationCodeInputRef5;
      case 6:
        return verificationCodeInputRef6;
    }
  };

  const isSubmitVerificationCodeButtonEnabled = () => {
    if (
      !isLoadingSendAccountActivationCodeEmail &&
      !isLoadingVerifyAccountActivationCode &&
      verificationCode.replace(/\s/, '').length == 6
    )
      return true;
    return false;
  };

  const onErrorModalCloseButtonClick = () => {
    if (isErrorGetAfterSignInData) {
      setIsGetAfterSignInDataEnabled(true);
    }
    setHasError(false);
  };

  return (
    <S.Container
      tabIndex={-1}
      onKeyDown={event => onPageContainerKeyDownHandler(event)}
    >
      <S.BackgroundImageContainer>
        <S.BackgroundImageInnerContainer>
          <S.BackgroundImageLeft src={SkorrOnboardingBackgroundImage} />
        </S.BackgroundImageInnerContainer>
        <S.BackgroundImageInnerContainer>
          <S.BackgroundImageRight src={SkorrOnboardingBackgroundImage} />
        </S.BackgroundImageInnerContainer>
      </S.BackgroundImageContainer>

      <S.Navbar>
        <S.NavbarInnerContainer>
          <S.NavbarAnchorTag>
            <S.NavbarImage
              src={SkorrLogo}
              onClick={() => window.open('https://www.skorr.social', '_blank')}
            />
            <S.NavbarImageSmallWindow
              src={SkorrLogoSmallWindow}
              onClick={() => window.open('https://www.skorr.social', '_blank')}
            />
          </S.NavbarAnchorTag>
        </S.NavbarInnerContainer>
      </S.Navbar>

      <S.FormSectionContainer>
        <S.FormOuterContainer>
          <S.FormContainer>
            <LoaderLocal
              show={isFetchingGetAfterSignInData || showLoaderLocal}
              message={props.t('generic.loading1')}
              borderRadius={'2.4rem'}
              spinner={true}
              spinnerFontSize={'1.7rem'}
            >
              <S.ForgotPasswordHeaderContainer>
                <S.ForgotPasswordBackArrowContainer
                  onClick={() => {
                    sessionStorage.removeItem('jwt');
                    navigate('/');
                  }}
                >
                  <BackArrowIcon width={'2.4rem'} height={'2.4rem'} />
                </S.ForgotPasswordBackArrowContainer>
                <S.ForgotPasswordTitleContainer>
                  {props.t('signin.generic.accountActivationCode')}
                </S.ForgotPasswordTitleContainer>
                <S.HiddenHeaderElement />
              </S.ForgotPasswordHeaderContainer>

              <S.ForgotPasswordContentOuterContainer>
                <S.ForgotPasswordContentTextContainer>
                  {props.t('signin.generic.insertActivationCode')}
                </S.ForgotPasswordContentTextContainer>
                <S.ForgotPasswordCodeInputContainer>
                  <ReactInputVerificationCode
                    autoFocus={true}
                    length={6}
                    placeholder=""
                    onChange={setVerificationCode}
                    value={verificationCode}
                  />
                </S.ForgotPasswordCodeInputContainer>
              </S.ForgotPasswordContentOuterContainer>

              <S.ForgotPasswordSubmitVerificationCodeButtonContainer>
                <Button
                  borderRadius={'0.6rem'}
                  borderWidth={'0rem'}
                  height={'4rem'}
                  fontSize={'1.6rem'}
                  display={'block'}
                  width={'100%'}
                  textAlign={'center'}
                  marginRight={'4rem'}
                  fontWeight={'500'}
                  cursorOnDisable={'default'}
                  blockHoverStylesOnDisable={true}
                  pointerEventsOnDisable={'none'}
                  disabled={!isSubmitVerificationCodeButtonEnabled()}
                  onClick={() =>
                    mutateVerifyAccountActivationCodeEmail({
                      code: verificationCode,
                    })
                  }
                >
                  {props.t('generic.submit')}
                </Button>
              </S.ForgotPasswordSubmitVerificationCodeButtonContainer>

              <S.SignInFormContentDivider />

              <S.SignInFormFooter>
                {props.t('signin.generic.didntReceiveAnEmail')}
                {numberOfSecondsRemaining == 0 ? (
                  <S.SignInFormFooterLink
                    onClick={() => mutateSendAccountActivationCodeEmail()}
                  >
                    {props.t('signin.generic.resendEmail')}
                  </S.SignInFormFooterLink>
                ) : (
                  <S.ForgotPasswordFooterResendEmailTimer>
                    {`${props.t(
                      'signin.generic.resendIn',
                    )} ${numberOfSecondsRemaining}s`}
                  </S.ForgotPasswordFooterResendEmailTimer>
                )}
              </S.SignInFormFooter>
            </LoaderLocal>
          </S.FormContainer>

          <S.SignInPageFooter>
            <S.SignInPageFooterLinksContainer>
              <S.SignInPageFooterLink
                onClick={() =>
                  window.open('https://www.skorr.social', '_blank')
                }
              >
                {props.t('signin.generic.about')}
              </S.SignInPageFooterLink>
              <S.SignInPageFooterLink
                onClick={() =>
                  window.open('https://skorr.social/terms', '_blank')
                }
              >
                {props.t('signin.generic.terms')}
              </S.SignInPageFooterLink>
              <S.SignInPageFooterLink
                onClick={() =>
                  window.open('https://skorr.social/knowledge-base', '_blank')
                }
              >
                {props.t('signin.generic.help')}
              </S.SignInPageFooterLink>
            </S.SignInPageFooterLinksContainer>
            <S.SignInPageFooterLangCopyRightsContainer>
              <S.CopyRightSpan>
                {props.t('signin.generic.copyright')}
              </S.CopyRightSpan>
            </S.SignInPageFooterLangCopyRightsContainer>
          </S.SignInPageFooter>
        </S.FormOuterContainer>

        <S.SecondBackgroundImageContainer />
      </S.FormSectionContainer>

      <NotifyCustom
        location={'bottom'}
        show={isLoadingSendAccountActivationCodeEmail}
        themeStyles={(themeGlobal as any).signin['regular-notify']}
      >
        <S.NotifyInnerContainer>
          <InputSpinnerIcon
            containerWidth={'2rem'}
            containerHeight={'2rem'}
            containerMarginRight={'1rem'}
          />
          {props.t('signin.generic.sendingAccountActivationCodeToEmail')}
        </S.NotifyInnerContainer>
      </NotifyCustom>

      <NotifyCustom
        location={'bottom'}
        show={showCodeSentToEmailNotification}
        themeStyles={(themeGlobal as any).signin['regular-notify']}
      >
        <S.NotifyInnerContainer>
          <EnvelopeIcon width={'2rem'} height={'2rem'} marginRight={'1rem'} />
          {props.t('signin.generic.accountActivationCodeWasSentToEmail')}
        </S.NotifyInnerContainer>
      </NotifyCustom>

      <NotifyCustom
        location={'bottom'}
        show={isLoadingVerifyAccountActivationCode}
        themeStyles={(themeGlobal as any).signin['regular-notify']}
      >
        <S.NotifyInnerContainer>
          <InputSpinnerIcon
            containerWidth={'2rem'}
            containerHeight={'2rem'}
            containerMarginRight={'1rem'}
          />
          {props.t('signin.generic.activatingYourAccount')}
        </S.NotifyInnerContainer>
      </NotifyCustom>

      <NotifyCustom
        location={'bottom'}
        show={showAccoundActivatedNotification}
        themeStyles={(themeGlobal as any).signin['success-notify']}
      >
        <S.NotifyInnerContainer>
          <InputCheckMarkIcon
            usage={'notifyCustom'}
            containerWidth={'2rem'}
            containerHeight={'2rem'}
            containerMarginRight={'1rem'}
          />
          {props.t('signin.generic.accountActivatedSuccessfully')}
        </S.NotifyInnerContainer>
      </NotifyCustom>

      <GenericModal
        show={hasError}
        title={props.t('generic.uhoh')}
        body={msgError}
        primaryButtonText={props.t('generic.close')}
        primaryButtonAction={() => onErrorModalCloseButtonClick()}
      />
    </S.Container>
  );
};

export default withTranslation()(AccountActivation);
