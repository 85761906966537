/* eslint-disable import/extensions */
import styled, { useTheme } from 'styled-components';
import { useState, useEffect } from 'react';

import SVG from './Styles';

interface IEyeIconV2Props {
  containerWidth?: string;
  containerHeight?: string;
  containerPaddingTop?: string;
  containerPaddingBottom?: string;
  containerPaddingLeft?: string;
  containerPaddingRight?: string;
  containerMarginTop?: string;
  containerMarginBottom?: string;
  containerMarginLeft?: string;
  containerMarginRight?: string;
  svgWidth?: string;
  svgHeight?: string;
  rotate?: string;
  cursor?: string;
}

interface IContainerProps {
  width?: string;
  height?: string;
  paddingTop?: string;
  paddingBottom?: string;
  paddingLeft?: string;
  paddingRight?: string;
  marginTop?: string;
  marginBottom?: string;
  marginLeft?: string;
  marginRight?: string;
}

const Container = styled.div<IContainerProps>`
  border-width: 0;
  outline: 0;
  width: ${props => (props.width ? props.width : 'auto')};
  height: ${props => (props.height ? props.height : 'auto')};
  padding-top: ${props => (props.paddingTop ? props.paddingTop : '0')};
  padding-bottom: ${props => (props.paddingBottom ? props.paddingBottom : '0')};
  padding-left: ${props => (props.paddingLeft ? props.paddingLeft : '0')};
  padding-right: ${props => (props.paddingRight ? props.paddingRight : '0')};
  margin-top: ${props => (props.marginTop ? props.marginTop : '0')};
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : '0')};
  margin-left: ${props => (props.marginLeft ? props.marginLeft : '0')};
  margin-right: ${props => (props.marginRight ? props.marginRight : '0')};
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;

const EyeV2Icon = (props: IEyeIconV2Props) => {
  const themeGlobal = useTheme();
  const [svgColor, setSvgColor] = useState<string>(() => {
    return (themeGlobal as any).icons.eyeV2Icon;
  });

  useEffect(() => {
    setSvgColor((themeGlobal as any).icons.eyeV2Icon);
  }, [(themeGlobal as any).darkMode]);

  return (
    <Container
      width={props.containerWidth}
      height={props.containerHeight}
      paddingTop={props.containerPaddingTop}
      paddingBottom={props.containerPaddingBottom}
      paddingLeft={props.containerPaddingLeft}
      paddingRight={props.containerPaddingRight}
      marginTop={props.containerMarginTop}
      marginBottom={props.containerMarginBottom}
      marginLeft={props.containerMarginLeft}
      marginRight={props.containerMarginRight}
    >
      <SVG
        viewBox="0 0 32 32"
        width={props.svgWidth ? props.svgWidth : '100%'}
        height={props.svgHeight ? props.svgHeight : '100%'}
        rotateNotNative={props.rotate}
        cursor={props.cursor}
      >
        <path
          fill={svgColor}
          d="M16 9.048c-7.244 0-12.817 6.381-12.817 6.381l-0.516 0.571 0.516 0.571c0 0 5.081 5.795 11.84 6.327 0.323 0.041 0.645 0.055 0.977 0.055s0.655-0.013 0.977-0.055c6.759-0.533 11.84-6.327 11.84-6.327l0.516-0.571-0.516-0.571c0 0-5.573-6.381-12.817-6.381zM16 10.787c1.915 0 3.68 0.523 5.213 1.221 0.553 0.916 0.869 1.972 0.869 3.123 0 3.14-2.356 5.72-5.404 6.056-0.017 0.004-0.037-0.004-0.055 0-0.207 0.011-0.415 0.027-0.624 0.027-0.231 0-0.455-0.013-0.679-0.027-3.048-0.336-5.404-2.916-5.404-6.056 0-1.133 0.305-2.189 0.841-3.096h-0.027c1.548-0.712 3.333-1.248 5.268-1.248zM16 12.524c-1.439 0-2.607 1.168-2.607 2.607s1.168 2.607 2.607 2.607 2.607-1.168 2.607-2.607-1.168-2.607-2.607-2.607zM8.396 13.339c-0.136 0.584-0.217 1.171-0.217 1.792 0 1.524 0.435 2.949 1.195 4.155-2.189-1.265-3.709-2.776-4.208-3.285 0.417-0.428 1.581-1.561 3.231-2.661zM23.604 13.339c1.649 1.1 2.813 2.233 3.232 2.661-0.499 0.509-2.020 2.020-4.209 3.285 0.76-1.205 1.195-2.631 1.195-4.155 0-0.621-0.083-1.215-0.217-1.792z"
        ></path>
      </SVG>
    </Container>
  );
};

export default EyeV2Icon;
