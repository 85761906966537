/* eslint-disable consistent-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-undef */
/* eslint-disable default-case */
/* eslint-disable import/extensions */
import { useState, useEffect, useRef } from 'react';
import { withTranslation } from 'react-i18next';

import * as S from './Styles';
import { IInputControlV2Props, IInputStates } from './types';
import { valueEmpty } from '../../../validations/validationFunctions';
import { useIsEmailInUse } from '../../../Services/Utils/CustomHooks';
import InputPasswordEyeIcon from '../../../assets/iconComponents/InputPasswordEyeIcon';
import InputCheckMarkIcon from '../../../assets/iconComponents/InputCheckMarkIcon';
import InputErrorIcon from '../../../assets/iconComponents/InputErrorIcon';
import InputLoaderIcon from '../../../assets/iconComponents/InputSpinnerIcon/InputSpinnerIcon';

const keyPressedValid = (keyPressed: string) => {
  switch (keyPressed) {
    case 'ArrowUp':
    case 'ArrowDown':
    case 'ArrowLeft':
    case 'ArrowRight':
    case 'Control':
    case 'Home':
    case 'End':
    case 'Shift':
    case 'Enter':
    case 'Tab':
      return false;
  }
  return true;
};

const InputControlV2 = (props: IInputControlV2Props) => {
  const [inputState, setInputState] = useState<IInputStates>({
    valid: false,
    warning: false,
    empty: true,
    default: true,
    focused: false,
  });
  const [validating, setValidating] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>();
  const latestTimerId = useRef<NodeJS.Timeout>();
  const [blockApiRequest, setBlockApiRequest] = useState<boolean>(true);
  const warningMessageToBeDisplayed = useRef<string | undefined>();
  const [type, setType] = useState<string>();
  const {
    data: isEmailInUse,
    isFetching: isFetchingIsEmailInUse,
    refetch: refetchIsEmailInUse,
    isSuccess: isSuccessIsEmailInUse,
    isError: isErrorIsEmailInUse,
  } = useIsEmailInUse(
    props.value as string,
    blockApiRequest,
    !!props.verifyEmailAvailability,
  );

  useEffect(() => {
    setType(props.type || 'text');
    if (props.focusOnMount) {
      if (inputRef.current) {
        inputRef.current?.focus();
      }
    }
  }, []);

  useEffect(() => {
    if (inputState.warning != true) {
      setInputState({
        ...inputState,
        warning: false,
      });
    }
  }, [props.value]);

  useEffect(() => {
    if (
      props.validateOnFirstRender &&
      (props.validationFunction || props.verifyEmailAvailability) &&
      !valueEmpty(props.value ? (props.value as string) : '')
    ) {
      clearTimeout(latestTimerId.current);
      latestTimerId.current = setTimeout(() => {
        if (props.validationFunction) {
          if (
            props.validationFunction(
              props.value ? (props.value as string) : '',
            ) != true
          ) {
            setInputState({
              ...inputState,
              valid: false,
              default: false,
              warning: true,
              empty: (props.value ? (props.value as string) : '').length == 0,
            });
            setBlockApiRequest(true);
            warningMessageToBeDisplayed.current = props.t(
              props.invalidFieldWarningMessage
                ? props.invalidFieldWarningMessage
                : 'errors.invalidField',
            );
          } else if (!props.verifyEmailAvailability) {
            setInputState({
              valid: true,
              warning: false,
              default: false,
              empty: false,
            });
            warningMessageToBeDisplayed.current = undefined;
          } else {
            setBlockApiRequest(false);
            refetchIsEmailInUse();
          }
        } else {
          setBlockApiRequest(false);
          refetchIsEmailInUse();
        }
        latestTimerId.current = undefined;
        setValidating(false);
      }, 1500);
    }
  }, []);

  useEffect(() => {
    if (valueEmpty(props.value as string | undefined)) {
      setInputState({
        ...inputState,
        empty: true,
        warning: false,
        valid: false,
      });
      setValidating(false);
    }
    if (valueEmpty(props.value as string | undefined) && inputState.focused) {
      warningMessageToBeDisplayed.current = undefined;
    }
    if (!valueEmpty(props.value as string | undefined)) {
      setInputState({
        ...inputState,
        empty: false,
      });
    }
    if (
      props.mandatoryField &&
      !(props.validationFunction || props.verifyEmailAvailability) &&
      inputState.warning == true &&
      !valueEmpty(props.value as string | undefined)
    ) {
      setInputState({
        ...inputState,
        warning: false,
        empty: false,
      });
    }
  }, [props.value]);

  useEffect(() => {
    if (props.verifyEmailAvailability && latestTimerId.current == undefined) {
      if (isErrorIsEmailInUse) {
        warningMessageToBeDisplayed.current = props.t(
          'errors.emailInUseRequestError',
        );
        setInputState({
          ...inputState,
          valid: false,
          default: false,
          warning: true,
          empty: false,
        });
      }
      if (!isFetchingIsEmailInUse && isSuccessIsEmailInUse) {
        if (isEmailInUse) {
          if (props.emailBeingInUseNecessary) {
            setInputState({
              empty: false,
              valid: true,
              default: false,
              warning: false,
            });
          } else {
            setInputState({
              empty: false,
              valid: false,
              default: false,
              warning: true,
            });
            warningMessageToBeDisplayed.current = props.t('errors.emailInUse');
          }
        } else {
          setInputState({
            empty: false,
            valid: true,
            default: false,
            warning: false,
          });
          warningMessageToBeDisplayed.current = undefined;
        }
      }
    }
  }, [isFetchingIsEmailInUse]);

  useEffect(() => {
    if (props.setIsInvalidOrProcessing) {
      if (
        !props.mandatoryField &&
        !props.validationFunction &&
        !props.verifyEmailAvailability &&
        valueEmpty(props.value as string | undefined)
      )
        props.setIsInvalidOrProcessing(false);
      else props.setIsInvalidOrProcessing(!inputState.valid);
    }
  }, [inputState.valid]);

  useEffect(() => {
    if (props.setIsInvalidOrProcessing) {
      if (
        !(validating || latestTimerId.current || isFetchingIsEmailInUse) &&
        inputState.valid
      ) {
        props.setIsInvalidOrProcessing(false);
      }
    }
  }, [validating, latestTimerId.current, isFetchingIsEmailInUse]);

  useEffect(() => {
    if (props.resetInputStateTrigger) {
      setInputState({
        valid: false,
        warning: false,
        empty: true,
        default: true,
        focused: false,
      });
      warningMessageToBeDisplayed.current = undefined;
    }
  }, [props.resetInputStateTrigger]);

  const notValidatingInputValue = () => {
    if (
      !(
        latestTimerId.current ||
        (props.verifyEmailAvailability && isFetchingIsEmailInUse) ||
        validating
      )
    )
      return true;
    return false;
  };

  const showLoader = () => {
    if (
      !notValidatingInputValue() &&
      !valueEmpty(props.value as string | undefined)
    ) {
      return true;
    }
    return false;
  };

  const showCheckMark = () => {
    if (props.forceCheckMarkIcon) return true;
    if (!props.allowCheckMarkIcon) return false;
    if (props.type == 'password' && inputState.focused) return false;
    if (notValidatingInputValue() && inputState.valid == true) return true;
    return false;
  };

  const showErrorIcon = () => {
    if (props.forceErrorIcon) return true;
    if (!props.allowErrorIcon) return false;
    if (props.type == 'password' && inputState.focused) return false;
    if (
      notValidatingInputValue() &&
      inputState.warning == true &&
      inputState.focused == true &&
      !valueEmpty(props.value as string | undefined)
    )
      return true;
    return false;
  };

  const showPasswordEyeIcon = () => {
    // console.log("PROPS.TYPE: ", props.type)
    // console.log("INPUT STATE FOCUSED: ", inputState.focused)
    if (
      props.type == 'password' &&
      notValidatingInputValue() &&
      !showCheckMark() &&
      !showErrorIcon() &&
      inputState.focused
    )
      return true;
    return false;
  };

  const onChangeHandler = (value: string) => {
    props.onChange(value);
  };

  const onBlurHandler = (event: any) => {
    if (props.onBlur) {
      props.onBlur();
    }
    if (props.mandatoryField && valueEmpty(props.value as string | undefined)) {
      setInputState({
        valid: false,
        warning: true,
        empty: true,
        default: false,
        focused: false,
      });
      warningMessageToBeDisplayed.current = props.t(
        props.requiredFieldWarningMessage
          ? props.requiredFieldWarningMessage
          : 'generic.requiredField',
      );
    } else setInputState({ ...inputState, focused: false });
    if (props.setBlurred) props.setBlurred(true);
  };

  const onFocusHandler = () => {
    if (props.onFocus) {
      props.onFocus();
    }
    // if the "Mandatory field" message is being displayed then the input has been touched and is now empty and when an empty input is focused no warning message is to be displayed
    if (
      warningMessageToBeDisplayed.current ==
      props.t(
        props.requiredFieldWarningMessage
          ? props.requiredFieldWarningMessage
          : 'generic.requiredField',
      )
    ) {
      warningMessageToBeDisplayed.current = undefined;
    }

    setInputState({ ...inputState, focused: true });
    if (props.setTouched) props.setTouched(true);
    if (props.setFocused) props.setFocused(true);
    if (valueEmpty(props.value as string | undefined)) {
      setInputState({
        ...inputState,
        warning: false,
        focused: true,
      });
    }
  };

  useEffect(() => {
    const x = 'x';
  }, [inputState]);

  // the "validating" state variable's value is set to true so that the loader icon doesn't stop being displayed inbetween key strokes
  const onKeyDown = (key: string) => {
    if (keyPressedValid(key)) {
      clearTimeout(latestTimerId.current);
      setBlockApiRequest(true);
      if (props.validationFunction || props.verifyEmailAvailability) {
        setValidating(true);
        if (props.setIsInvalidOrProcessing)
          props.setIsInvalidOrProcessing(true);
      }
    }
  };

  const onKeyUp = (inputValue: string, key: string) => {
    if (key == 'Enter') {
      if (props.enterKeyPressedHandler && notValidatingInputValue())
        props.enterKeyPressedHandler();
    } else if (keyPressedValid(key)) {
      if (
        (props.validationFunction || props.verifyEmailAvailability) &&
        !valueEmpty(inputValue)
      ) {
        clearTimeout(latestTimerId.current);
        latestTimerId.current = setTimeout(() => {
          if (props.validationFunction) {
            if (props.validationFunction(inputValue) != true) {
              setInputState({
                ...inputState,
                valid: false,
                default: false,
                warning: true,
                empty: inputValue.length == 0,
                focused: key != 'Unidentified',
              });
              setBlockApiRequest(true);
              warningMessageToBeDisplayed.current = props.t(
                props.invalidFieldWarningMessage
                  ? props.invalidFieldWarningMessage
                  : 'errors.invalidField',
              );
            } else if (!props.verifyEmailAvailability) {
              setInputState({
                valid: true,
                warning: false,
                default: false,
                empty: false,
                focused: key != 'Unidentified',
              });
              warningMessageToBeDisplayed.current = undefined;
            } else {
              setBlockApiRequest(false);
              refetchIsEmailInUse();
            }
          } else {
            setBlockApiRequest(false);
            refetchIsEmailInUse();
          }
          latestTimerId.current = undefined;
          setValidating(false);
        }, 1500);
      }
    }
  };

  const getInputFieldPaddingRight = () => {
    if (
      showLoader() ||
      showCheckMark() ||
      showErrorIcon() ||
      showPasswordEyeIcon()
    ) {
      return '3.7rem';
    }
    return props.inputFieldPaddingRight;
  };

  const getWarningMessage = () => {
    if (props.showNoErrorMessage != true) {
      if (props.forceErrorDisplay) {
        return (
          <S.WarningMessageContainer
            onClick={event => event.stopPropagation()}
            topOffset={props.warningMessageContainerTopOffset}
            leftOffset={props.warningMessageContainerLeftOffset}
            fontSize={props.warningMessageFontSize}
            visible={true}
            positionAbsolute={props.warningMessageContainerPositionAbsolute}
          >
            {props.forceErrorDisplay}
          </S.WarningMessageContainer>
        );
      }
      return (
        <S.WarningMessageContainer
          onClick={event => event.stopPropagation()}
          topOffset={props.warningMessageContainerTopOffset}
          leftOffset={props.warningMessageContainerLeftOffset}
          fontSize={props.warningMessageFontSize}
          visible={!!warningMessageToBeDisplayed.current}
          positionAbsolute={props.warningMessageContainerPositionAbsolute}
        >
          {warningMessageToBeDisplayed.current}
          {warningMessageToBeDisplayed.current ==
          props.t('errors.emailInUseRequestError') ? (
            <S.TryAgainContainer onClick={() => refetchIsEmailInUse()}>
              {props.t('generic.tryAgain')}
            </S.TryAgainContainer>
          ) : (
            <></>
          )}
        </S.WarningMessageContainer>
      );
    }
  };

  const getInputField = () => {
    return (
      <S.InputFieldContainer
        width={props.inputFieldContainerWidth}
        height={props.inputFieldContainerHeight}
        marginTop={props.inputFieldContainerMarginTop}
        marginBottom={props.inputFieldContainerMarginBottom}
        marginLeft={props.inputFieldContainerMarginLeft}
        marginRight={props.inputFieldContainerMarginRight}
        onClick={() => inputRef.current?.focus()}
        inputState={inputState}
        borderColorHover={props.inputFieldBorderColorHover}
        allowGreenBorder={
          props.allowGreenBorder ? props.allowGreenBorder : false
        }
        disabled={props.disabledNotNative || props.disabled}
        forceBorderColorGreen={props.forceBorderColorGreen}
        forceBorderColorRed={props.forceBorderColorRed}
        forceBorderColorGreenOnHoverOrFocus={
          props.forceBorderColorGreenOnHoverOrFocus
        }
        forceBorderColorRedOnHoverOrFocus={
          props.forceBorderColorRedOnHoverOrFocus
        }
      >
        {props.type != 'textarea' ? (
          <S.InputField
            themeStyles={props.inputFieldThemeStyles}
            type={type}
            inputState={inputState}
            value={props.value}
            width={props.inputFieldWidth}
            height={props.inputFieldHeight}
            placeholder={props.placeholder}
            borderColor={props.inputFieldBorderColor}
            borderColorHover={props.inputFieldBorderColorHover}
            borderColorFocus={props.inputFieldBorderColorFocus}
            borderStyle={props.inputFieldBorderStyle}
            borderWidth={props.inputFieldBorderWidth}
            borderRadius={props.inputFieldBorderRadius}
            paddingTop={props.inputFieldPaddingTop}
            paddingBottom={props.inputFieldPaddingBottom}
            paddingLeft={props.inputFieldPaddingLeft}
            paddingRight={getInputFieldPaddingRight()}
            fontSize={props.inputFieldFontSize}
            caretColor={props.inputFieldCaretColor}
            color={props.inputFieldColor}
            lineHeight={props.inputFieldLineHeight}
            outline={props.inputFieldOutline}
            onChange={event => onChangeHandler(event.target.value)}
            onFocus={() => onFocusHandler()}
            onBlur={event => onBlurHandler(event)}
            ref={props.refNotNative || inputRef}
            onKeyDown={event => onKeyDown(event.key)}
            onKeyUp={event => onKeyUp((event.target as any).value, event.key)}
            allowGreenBorder={
              props.allowGreenBorder ? props.allowGreenBorder : false
            }
            disabledNotNative={props.disabledNotNative}
            forceBorderColorDefault={props.forceBorderColorDefault}
            forceBorderColorGreen={props.forceBorderColorGreen}
            forceBorderColorRed={props.forceBorderColorRed}
            forceBorderColorGreenOnHoverOrFocus={
              props.forceBorderColorGreenOnHoverOrFocus
            }
            forceBorderColorRedOnHoverOrFocus={
              props.forceBorderColorRedOnHoverOrFocus
            }
            disabled={props.disabled}
          />
        ) : (
          <S.TextAreaField
            themeStyles={props.inputFieldThemeStyles}
            inputState={inputState}
            value={props.value}
            width={props.inputFieldWidth}
            height={props.inputFieldHeight}
            placeholder={props.placeholder}
            borderColor={props.inputFieldBorderColor}
            borderColorHover={props.inputFieldBorderColorHover}
            borderColorFocus={props.inputFieldBorderColorFocus}
            borderStyle={props.inputFieldBorderStyle}
            borderWidth={props.inputFieldBorderWidth}
            borderRadius={props.inputFieldBorderRadius}
            paddingTop={props.inputFieldPaddingTop}
            paddingBottom={props.inputFieldPaddingBottom}
            paddingLeft={props.inputFieldPaddingLeft}
            paddingRight={getInputFieldPaddingRight()}
            fontSize={props.inputFieldFontSize}
            caretColor={props.inputFieldCaretColor}
            color={props.inputFieldColor}
            lineHeight={props.inputFieldLineHeight}
            outline={props.inputFieldOutline}
            onChange={event => onChangeHandler(event.target.value)}
            onFocus={() => onFocusHandler()}
            onBlur={event => onBlurHandler(event)}
            ref={props.refNotNative || inputRef}
            onKeyDown={event => onKeyDown(event.key)}
            onKeyUp={event => onKeyUp((event.target as any).value, event.key)}
            allowGreenBorder={
              props.allowGreenBorder ? props.allowGreenBorder : false
            }
            disabledNotNative={props.disabledNotNative}
            forceBorderColorGreen={props.forceBorderColorGreen}
            forceBorderColorRed={props.forceBorderColorRed}
            forceBorderColorGreenOnHoverOrFocus={
              props.forceBorderColorGreenOnHoverOrFocus
            }
            forceBorderColorRedOnHoverOrFocus={
              props.forceBorderColorRedOnHoverOrFocus
            }
            disabled={props.disabled}
          />
        )}

        <S.InputIconContainer
          opacity={showPasswordEyeIcon() ? '1' : '0'}
          cursor={'pointer'}
          pointerEvents={showPasswordEyeIcon() ? 'all' : 'none'}
          onClick={() => setType(type == 'password' ? 'text' : 'password')}
          onMouseDown={event => event.preventDefault()}
        >
          <InputPasswordEyeIcon />
        </S.InputIconContainer>

        <S.InputIconContainer
          opacity={showCheckMark() ? '1' : '0'}
          pointerEvents={'none'}
        >
          <InputCheckMarkIcon svgWidth={'100%'} svgHeight={'100%'} />
        </S.InputIconContainer>

        <S.InputIconContainer
          opacity={showErrorIcon() ? '1' : '0'}
          pointerEvents={'none'}
        >
          <InputErrorIcon width={'50%'} height={'50%'} />
        </S.InputIconContainer>

        <S.InputIconContainer
          opacity={showLoader() ? '1' : '0'}
          pointerEvents={'none'}
        >
          <InputLoaderIcon />
        </S.InputIconContainer>

        {props.type == 'password' &&
          (props.showPasswordTooltip || props.showPasswordTooltipAlways) && (
            <S.PasswordInfoContainer
              visible={!!inputState.focused}
              marginTop={props.passwordTooltipMarginTop}
              onClick={(e: any) => e.stopPropagation()}
            >
              <S.PasswordInfoIconContainer>
                <svg version="1.1" viewBox="0 0 32 32">
                  <path d="M16 5.551c-5.771 0-10.449 4.678-10.449 10.449s4.678 10.449 10.449 10.449c5.771 0 10.449-4.678 10.449-10.449s-4.678-10.449-10.449-10.449zM3.651 16c0-6.82 5.529-12.349 12.349-12.349s12.349 5.529 12.349 12.349c0 6.82-5.529 12.349-12.349 12.349s-12.349-5.529-12.349-12.349zM16 9.984c0.525 0 0.95 0.425 0.95 0.95v5.066c0 0.525-0.425 0.95-0.95 0.95s-0.95-0.425-0.95-0.95v-5.066c0-0.525 0.425-0.95 0.95-0.95zM15.050 21.066c0-0.525 0.425-0.95 0.95-0.95h0.013c0.525 0 0.95 0.425 0.95 0.95s-0.425 0.95-0.95 0.95h-0.013c-0.525 0-0.95-0.425-0.95-0.95z"></path>
                </svg>
              </S.PasswordInfoIconContainer>
              <div>{props.t('signin.generic.passwordTooltip')}</div>
            </S.PasswordInfoContainer>
          )}

        {getWarningMessage()}
      </S.InputFieldContainer>
    );
  };

  if (props.title)
    return (
      <S.InputFieldContainerWithTitle
        inputState={inputState}
        borderColorHover={props.inputFieldBorderColorHover}
        allowGreenBorder={
          props.allowGreenBorder ? props.allowGreenBorder : false
        }
        themeStyles={props.inputTitleThemeStyles}
        titleColorHover={props.inputTitleColorHover}
        flexGrow={props.inputFieldContainerWithTitleFlexGrow}
        forceBorderColorGreen={props.forceBorderColorGreen}
        forceBorderColorRed={props.forceBorderColorRed}
        forceBorderColorGreenOnHoverOrFocus={
          props.forceBorderColorGreenOnHoverOrFocus
        }
        forceBorderColorRedOnHoverOrFocus={
          props.forceBorderColorRedOnHoverOrFocus
        }
        width={props.inputFieldContainerWithTitleWidth}
        marginTop={props.inputFieldContainerWithTitleMarginTop}
        marginBottom={props.inputFieldContainerWithTitleMarginBottom}
        marginRight={props.inputFieldContainerWithTitleMarginRight}
        marginLeft={props.inputFieldContainerWithTitleMarginLeft}
      >
        <S.InputTitle
          themeStyles={props.inputTitleThemeStyles}
          fontSize={props.inputTitleFontSize}
          fontWeight={props.inputTitleFontWeight}
          lineHeight={props.inputTitleLineHeight}
          marginBottom={props.inputTitleMarginBottom}
          color={props.inputTitleColor}
          colorHover={props.inputTitleColorHover}
          showMandatoryAsterisk={props.showMandatoryAsterisk}
        >
          {props.title}
        </S.InputTitle>

        {getInputField()}
      </S.InputFieldContainerWithTitle>
    );

  return getInputField();
};

export default withTranslation()(InputControlV2);
