import { AxiosInstance } from 'axios';

import config from '../../../config';
import { GetExternalMediaKitConfigurationsByUserIdResponse } from './types';

const createExternalMediaKitInstanceApi = (instance: AxiosInstance) => {
  const getExternalMediaConfigurationsByUserId = async (userId: string) => {
    const url = `${config.ENDPOINTS.EXTERNAL_MEDIA_KIT.GET_EXTERNAL_MEDIA_KIT_CONFIGURATIONS_BY_USER_ID}/${userId}`;

    const {
      data: configurations,
    }: {
      data: GetExternalMediaKitConfigurationsByUserIdResponse;
    } = await instance.get(url);

    return configurations;
  };

  return {
    getExternalMediaConfigurationsByUserId,
  };
};

export default {
  createExternalMediaKitInstanceApi,
};
