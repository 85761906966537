import styled from 'styled-components';
import { TiTimes } from 'react-icons/ti';

import { IContinueButtonContainerProps } from './types';

export const OuterContainer = styled.div`
  background-color: ${props =>
    props.theme['additional-data-step']['outer-container-background-color']};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;

// &::-webkit-scrollbar {
//         width: 0.8rem;
//     };

//     &::-webkit-scrollbar-track {
//         border-radius: 0.4rem;
//         background: ${props => props.theme['container']['scrollbar-track-background-color']};
//     };

//     &::-webkit-scrollbar-thumb {
//         background: ${props => props.theme['container']['scrollbar-thumb-background-color']};
//         border-radius: 0.4rem;
//     };

//     &::-webkit-scrollbar-thumb:hover {
//         background: ${props => props.theme['container']['scrollbar-thumb-hover-background-color']};
//     };

//     scrollbar-color: ${props => props.theme['container']['scrollbar-thumb-background-color']} ${props => props.theme['container']['scrollbar-track-background-color']};
//     scrollbar-width: thin;
export const Container = styled.div`
  border-radius: 2.4rem;
  width: 84.1rem;
  height: 69.6rem;
  background-color: ${props =>
    props.theme['additional-data-step']['background-color']};
  box-shadow: ${props =>
    `0rem 1.6rem 2.4rem ${props.theme['additional-data-step']['box-shadow-color-1']}, 0rem 0.6rem 3rem ${props.theme['additional-data-step']['box-shadow-color-2']}, 0rem 0.8rem 1rem ${props.theme['additional-data-step']['box-shadow-color-3']}`};

  @media (max-width: 640px) {
    background-color: ${props =>
      props.theme.signin['signin-container-background-color-responsive']};
  }
`;

export const InnerContainer = styled.div`
  height: '69.6rem';
  display: 'flex';
  flexdirection: 'column';
`;

// &::-webkit-scrollbar {
//         width: 0.8rem;
//     };

//     &::-webkit-scrollbar-track {
//         border-radius: 0.4rem;
//         background: ${props => props.theme['container']['scrollbar-track-background-color']};
//     };

//     &::-webkit-scrollbar-thumb {
//         background: ${props => props.theme['container']['scrollbar-thumb-background-color']};
//         border-radius: 0.4rem;
//     };

//     &::-webkit-scrollbar-thumb:hover {
//         background: ${props => props.theme['container']['scrollbar-thumb-hover-background-color']};
//     };

//     scrollbar-color: ${props => props.theme['container']['scrollbar-thumb-background-color']} ${props => props.theme['container']['scrollbar-track-background-color']};
//     scrollbar-width: thin;
export const InfluencerContainer = styled.div`
  border-radius: 2.4rem;
  width: 84.1rem;
  height: 69.6rem;
  background-color: ${props =>
    props.theme['additional-data-step']['background-color']};
  box-shadow: ${props =>
    `0rem 1.6rem 2.4rem ${props.theme['additional-data-step']['box-shadow-color-1']}, 0rem 0.6rem 3rem ${props.theme['additional-data-step']['box-shadow-color-2']}, 0rem 0.8rem 1rem ${props.theme['additional-data-step']['box-shadow-color-3']}`};

  @media (max-width: 640px) {
    background-color: ${props =>
      props.theme.signin['signin-container-background-color-responsive']};
  }
`;

export const HeaderOuterContainer = styled.div`
  height: 7.12rem;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 2rem 4.3rem;
  font-size: 2.6rem;
  font-weight: 600;
  color: ${props => props.theme.text['color-26']};

  @media (max-width: 515px) {
    padding-left: 3rem;
    padding-right: 3rem;
  }
`;

export const HeaderTitleContainer = styled.div`
  margin: 0 auto;
`;

export const HeaderBackArrowContainer = styled.div`
  height: 4rem;
  width: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.8rem;
  transition: all 0.08s;
  opacity: 1;
  border-radius: 0.8rem;
  cursor: pointer;
  & path {
    fill: ${props =>
      props.theme.signin['signin-forgot-password-back-arrow-color']};
  }

  &:hover {
    background-color: ${props =>
      props.theme.signin[
        'signin-forgot-password-back-arrow-container-background-color-hover'
      ]};
  }
`;

export const HiddenHeaderElement = styled.div`
  height: 4rem;
  width: 4rem;
  visibility: hidden;
`;

export const HeaderDivider = styled.div`
  width: 100%;
  height: 0.1rem;
  background-color: ${props =>
    props.theme.signin['signin-content-divider-color']};
`;

export const FormContainer = styled.div`
  margin-top: 2.7rem;
  padding-left: 5.6rem;
  padding-right: 5.6rem;
  margin-bottom: 3.6rem;
  height: 56.36rem;
  overflow-y: overlay;

  &::-webkit-scrollbar {
    width: 0.8rem;
  }

  &::-webkit-scrollbar-track {
    border-radius: 0.4rem;
    background: ${props =>
      props.theme.container['scrollbar-track-background-color']};
  }

  &::-webkit-scrollbar-thumb {
    background: ${props =>
      props.theme.container['scrollbar-thumb-background-color']};
    border-radius: 0.4rem;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${props =>
      props.theme.container['scrollbar-thumb-hover-background-color']};
  }

  scrollbar-color: ${props =>
      props.theme.container['scrollbar-thumb-background-color']}
    ${props => props.theme.container['scrollbar-track-background-color']};
  scrollbar-width: thin;

  @media (max-width: 640px) {
    background-color: ${props =>
      props.theme.signin['signin-container-background-color-responsive']};
  }

  @media (max-width: 515px) {
    padding-left: 4rem;
    padding-right: 4rem;
  }
`;

export const CompanyDetailsContainer = styled.div``;

export const CompanyDetailsTitle = styled.div`
  font-size: 2rem;
  font-weight: 600;
  color: ${props => props.theme.text['color-26']};
`;

export const CompanyDetailsInputsContainer = styled.div`
  display: flex;
  padding-top: 2.5rem;

  @media (max-width: 850px) {
    flex-direction: column;
  }
`;

export const CompanyNameInputContainer = styled.div`
  width: calc(50% - 3rem);
  margin-right: 6rem;

  @media (max-width: 850px) {
    width: 100%;
    margin-right: 0rem;
    margin-bottom: 2rem;
  }
`;

export const CompanyWebsiteInputContainer = styled.div`
  width: calc(50% - 3rem);

  @media (max-width: 850px) {
    width: 100%;
  }
`;

export const BusinessProfileCreationContainer = styled.div`
  margin-top: 3.8rem;
`;

export const BusinessProfileCreationTitle = styled.div`
  font-size: 2rem;
  font-weight: 600;
  color: ${props => props.theme.text['color-26']};
  margin-bottom: 1rem;
`;

export const BusinessProfileCreationSubtitle = styled.div`
  font-size: 1.4rem;
  color: ${props => props.theme.text['color-27']};
`;

export const BusinessProfileCreationInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BusinessProfileCreationInnerContainer1 = styled.div`
  display: flex;
  margin-top: 2rem;

  @media (max-width: 753px) {
    flex-direction: column;
  }
`;

export const BPImagePickerContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  width: 26rem;

  @media (max-width: 753px) {
    width: 100%;
    align-items: center;
  }
`;

export const BPImageContainer = styled.div`
  width: 260px;
  height: 160px;
  position: relative;
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
`;

export const BPImage = styled.img`
  width: 90%;
  height: 100%;
  object-fit: cover;
`;

export const BPImageCrossContainer = styled.div`
  position: absolute;
  top: 5px;
  right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const BPImageCross = styled(TiTimes)``;

export const BPImagePickerButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const BusinessProfileCreationInputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 3rem;
  width: calc(100% - 26rem);
  justify-content: center;

  @media (max-width: 753px) {
    width: 100%;
  }
`;

export const BusinessProfileNameInputContainer = styled.div`
  width: 90%;
  margin-bottom: 2rem;

  @media (max-width: 753px) {
    width: 100%;
  }
`;

export const BusinessProfileWebsiteInputContainer = styled.div`
  width: 90%;

  @media (max-width: 753px) {
    width: 100%;
  }
`;

export const ContinueButtonContainer = styled.div<IContinueButtonContainerProps>`
  display: flex;
  justify-content: end;
  margin-top: ${props => (props.influencer ? 'auto' : '0')};
  padding-left: ${props => (props.influencer ? '5.6rem' : '0rem')};
  padding-right: ${props => (props.influencer ? '5.6rem' : '0rem')};
  margin-bottom: 3rem;
`;

export const NotifyInnerContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;
export const InfluencerNameAndCountriesContainer = styled.div`
  margin-top: 5rem;
  display: flex;
  justify-content: space-between;
`;

export const InfluencerNameInputContainer = styled.div`
  width: 58%;
`;

export const InfluencerCountryContainer = styled.div`
  width: 40%;
`;

export const InfluencerSocialNetworkAccountsContainer = styled.div`
  margin-top: 5rem;
  display: flex;
  flex-direction: column;
`;

export const TopicsInterestContainer = styled.div`
  margin-top: 5rem;
`;

export const TopicsInterestTitleContainer = styled.div`
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1rem;
  margin-bottom: 1.2rem;
  color: ${props => props.theme.text['color-27']};
  position: relative;
  display: inline-block;

  &::before {
    display: block;
    content: '*';
    position: absolute;
    left: 103%;
    font-weight: 800;
    color: ${props => props.theme.inputFieldV2['mandatory-asterisk-color']};
  }
`;

export const FooterContainer = styled.div`
  height: 6.12rem;
  padding: 0 5.6rem;
`;
