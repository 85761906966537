/* eslint-disable import/extensions */
/* eslint-disable eqeqeq */
/* eslint-disable no-param-reassign */
import axios from 'axios';
import axiosRetry from 'axios-retry';

import config from '../../config';
import { isAuthenticated, jwtParser } from '../../Utils/Utils';
import Auth from './Auth';
import Generic from './Generic';
import User from './User';
import Files from './Files';
import Exporter from './Exporter';
import Post from './Post';
import ExternalMediaKit from './ExternalMediaKit';

/**
 * axios instance
 */
const axiosClient = axios.create({
  baseURL: config.ENDPOINTS.SKORR_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
  timeout: 1000 * 60 * 10,
});

axiosRetry(axiosClient, {
  // retries: 3,
  retries: 0,
  retryDelay: axiosRetry.exponentialDelay,
  retryCondition: response => {
    return (
      response.code !== 'ECONNABORTED' &&
      (!response.response || response.response.status == 504)
    );
  },
});

/**
 * parse response
 */
function parseBody(response: any) {
  if (response.status === 200) {
    if (response.data?.token !== undefined) {
      jwtParser(response.data!.token);
    } else if (response.data?.result?.token !== undefined) {
      jwtParser(response.data!.result!);
    }
    return { data: response.data.result || response.data.output };
  }
  return response.data.messages;
}

// request header
axiosClient.interceptors.request.use(
  config => {
    const jwt = isAuthenticated();
    if (jwt !== undefined && jwt.token !== undefined) {
      // 'Authorization': "bearer " + credentials.t
      config.headers!!.Authorization = `bearer ${jwt.token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

// response parse
axiosClient.interceptors.response.use(
  response => {
    return parseBody(response);
  },
  error => {
    if (error.response !== undefined && error.response.data !== undefined) {
      throw error;
    } else {
      const errorObj = { response: { data: error.message } };
      throw errorObj;
    }
  },
);

export default {
  auth: Auth.createAuthInstanceApi(axiosClient),
  user: User.createUserInstanceApi(axiosClient),
  post: Post.createPostInstanceApi(axiosClient),
  generic: Generic.createGenericInstanceApi(axiosClient),
  externalMediaKit:
    ExternalMediaKit.createExternalMediaKitInstanceApi(axiosClient),
  files: Files.createFilesInstanceApi(axiosClient),
  exporter: Exporter.createExporterInstanceApi(axiosClient),
};
