import { UncontrolledTooltip } from 'reactstrap';
import styled from 'styled-components';

import { ITooltipProps } from './Types';

export const Tooltip = styled(UncontrolledTooltip)<ITooltipProps>`
  .tooltip-inner {
    display: inline-block !important;
    width: auto !important;
    max-width: ${props => (props.maxWidth ? props.maxWidth : '20rem')};
    text-align: center;
    padding-top: ${props => (props.paddingTop ? props.paddingTop : '0.5rem')};
    padding-bottom: ${props =>
      props.paddingBottom ? props.paddingBottom : '0.5rem'};
    padding-left: ${props => (props.paddingLeft ? props.paddingLeft : '1rem')};
    padding-right: ${props =>
      props.paddingRight ? props.paddingRight : '1rem'};
    margin-top: ${props => (props.marginTop ? props.marginTop : '0')};
    margin-bottom: ${props => (props.marginBottom ? props.marginBottom : '0')};
    margin-left: ${props => (props.marginLeft ? props.marginLeft : '0')};
    margin-right: ${props => (props.marginRight ? props.marginRight : '0')};
    background-color: ${props => {
      if (props.backgroundColor) return props.backgroundColor;
      return props.theme.tooltip['background-color'];
    }};
    color: ${props => {
      if (props.color) return props.color;
      return props.theme.tooltip.color;
    }};
    font-weight: ${props => (props.fontWeight ? props.fontWeight : '400')};
    font-family: 'Fira Sans', sans-serif;
    font-size: ${props => (props.fontSize ? props.fontSize : '1.4rem')};
    border-color: ${props => {
      if (props.borderColor) return props.borderColor;
      return props.theme.tooltip['border-color'];
    }};
    border-radius: ${props =>
      props.borderRadius ? props.bordeRadius : '0.7rem'};
    line-height: 2.1rem;
    box-shadow: ${props => props.theme.tooltip['box-shadow-color']} 0 0.2rem
      1rem 0;
  }
`;
