import { AxiosInstance } from 'axios';

import config from '../../../config';

const createGenericInstanceApi = (instance: AxiosInstance) => {
  const getLocations = () =>
    instance.get(config.ENDPOINTS.GENERIC.GET_LOCATIONS);

  const getTopicsInterest = () =>
    instance.get(config.ENDPOINTS.GENERIC.GET_TOPICS_INTEREST);

  return {
    getLocations,
    getTopicsInterest,
  };
};

export default {
  createGenericInstanceApi,
};
