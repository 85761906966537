/* eslint-disable no-lonely-if */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-var */
/* eslint-disable block-scoped-var */
/* eslint-disable no-param-reassign */
/* eslint-disable no-self-assign */
/* eslint-disable vars-on-top */
/* eslint-disable consistent-return */
/* eslint-disable default-case */
/* eslint-disable no-plusplus */
/* eslint-disable no-continue */
/* eslint-disable import/extensions */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import * as S from './Styles';
import { IPublicUserMediaKitProps } from './types';
import {
  useGetKpisOverviewDaily,
  useGetPublicPostsByPublicUserId,
  useGetPublicUserAudienceDistributionData,
  useGetPublicUserHashtagsLatest,
  useGetPublicUserKpisOverview,
} from './RQCustomHooks';
import UserPresentationalCard from '../../components/shared/MediaKit/UserPresentationalCard';
import {
  useGetPublicUserById,
  useGetSelfUser,
} from '../../Services/Utils/CustomHooks';
import Loader from '../../components/shared/LoaderFixed/LoaderFixed';
import { getDifferenceInDays } from '../../Services/Utils/Utils';
import { SocialNetworks, ViewTypes } from '../../Services/Utils/types';
import EvolutionPerformance from '../../components/shared/MediaKit/EvolutionPerformance';
import AudienceDistribution from '../../components/shared/MediaKit/AudienceDistribution';
import Posts from '../../components/shared/MediaKit/Posts';
import GenericModal from '../../components/shared/GenericModal/GenericModal';

const START_DATE_TIMESTAMP = new Date(
  new Date().getTime() - 30 * 24 * 3600 * 1000,
).setHours(0, 0, 0, 0);
const END_DATE_TIMESTAMP = new Date().setHours(23, 59, 59, 0);

const enum MediaKitSections {
  'USER_PRESENTATIONAL_CARD' = 'USER_PRESENTATIONAL_CARD',
  'EVOLUTION_PERFORMANCE' = 'EVOLUTION_PERFORMANCE',
  'AUDIENCE_DEMOGRAPHICS' = 'AUDIENCE_DEMOGRAPHICS',
  'POSTS' = 'POSTS',
}

export const orderKPIs = (dataKPIs: any) => {
  let indOfGreatest;
  let temp;

  for (let i = 0; i < dataKPIs.length - 1; i++) {
    indOfGreatest = 0;
    for (var j = 0; j < dataKPIs.length - i; j++) {
      if (dataKPIs[j].date > dataKPIs[indOfGreatest].date) {
        indOfGreatest = j;
      } else if (dataKPIs[j].date < dataKPIs[indOfGreatest].date) {
        indOfGreatest = indOfGreatest;
      }
    }
    temp = dataKPIs[j - 1];
    dataKPIs[j - 1] = dataKPIs[indOfGreatest];
    dataKPIs[indOfGreatest] = temp;
  }
  return dataKPIs;
};

export const filterKPIs = (data: any, viewType?: string) => {
  const filteredKPIsInitial = [];
  const filteredKPIsFinal = [];
  const olderKPIsInitial = [];
  const olderKPIsFinal = [];
  let hasNetwork;

  if (data.dataKPIs.length > 0) {
    // if the agent selected a time interval
    if (
      data.date[0].startDate !== undefined &&
      data.date[0].endDate !== undefined
    ) {
      data.setMostRecentDayWithData(undefined);

      const timeRangeLengthInDays = getDifferenceInDays(
        Date.parse(data.date[0].endDate),
        Date.parse(data.date[0].startDate),
      );

      for (let i = data.dataKPIs.length - 1; i >= 0; i--) {
        hasNetwork = false;
        // if the day is included in the selected time interval
        if (
          data.dataKPIs[i].date >= data.date[0].startDate &&
          data.dataKPIs[i].date <= data.date[0].endDate
        ) {
          // if in public user analytics page
          if (viewType === ViewTypes.PUBLIC) {
            filteredKPIsInitial.push(data.dataKPIs[i]);
          } else {
            // search selected social network in day
            for (const kpiKey in data.dataKPIs[i]) {
              if (
                kpiKey.substring(0, data.networkSelectedId.length) ===
                  data.networkSelectedId &&
                kpiKey[data.networkSelectedId.length] === '.'
              ) {
                hasNetwork = true;
                filteredKPIsInitial.push(data.dataKPIs[i]);
              }

              if (hasNetwork) {
                break;
              }
            }
          }
        }
        // if day is not included in selected time interval
        else {
          // if day is prior to the first day of selected time interval and its distance to the first day of selected time interval is not greater than the number of days of the selected time interval
          if (
            data.dataKPIs[i].date <= data.date[0].startDate &&
            getDifferenceInDays(
              Date.parse(data.date[0].startDate),
              Date.parse(data.dataKPIs[i].date),
            ) <= timeRangeLengthInDays
          ) {
            // if in public user analytics page
            if (viewType === ViewTypes.PUBLIC) {
              olderKPIsInitial.push(data.dataKPIs[i]);
            } else {
              // search selected social network in day
              for (const kpiKey in data.dataKPIs[i]) {
                if (
                  kpiKey.substring(0, data.networkSelectedId.length) ===
                    data.networkSelectedId &&
                  kpiKey[data.networkSelectedId.length] === '.'
                ) {
                  hasNetwork = true;
                  olderKPIsInitial.push(data.dataKPIs[i]);
                }

                if (hasNetwork) {
                  break;
                }
              }
            }
          }
        }
      }
    }
    // if the agent didn't select a time interval
    else if (
      data.date[0].startDate === undefined &&
      data.date[0].endDate === undefined
    ) {
      const todayTimestamp: any = {};
      // search for the most recent day with available kpis for the selected social network
      for (let i = data.dataKPIs.length - 1; i >= 0; i--) {
        if (data.dataKPIs[i].networks.indexOf(data.networkSelectedId) < 0) {
          continue;
        }
        todayTimestamp[data.networkSelectedId] = data.dataKPIs[i].date;
        data.setMostRecentDayWithData(data.dataKPIs[i].date);
        break;
      }

      for (let i = data.dataKPIs.length - 1; i >= 0; i--) {
        hasNetwork = false;
        // the day is inside the interval
        if (
          data.dataKPIs[i].date <= todayTimestamp[data.networkSelectedId] &&
          getDifferenceInDays(
            todayTimestamp[data.networkSelectedId],
            data.dataKPIs[i].date,
          ) <= 30
        ) {
          // if in public user analytics page
          if (viewType === ViewTypes.PUBLIC) {
            filteredKPIsInitial.push(data.dataKPIs[i]);
          } else {
            // search selected social network in day
            for (const kpiKey in data.dataKPIs[i]) {
              if (
                kpiKey.substring(0, data.networkSelectedId.length) ===
                  data.networkSelectedId &&
                kpiKey[data.networkSelectedId.length] === '.'
              ) {
                hasNetwork = true;
                filteredKPIsInitial.push(data.dataKPIs[i]);
              }

              if (hasNetwork) {
                break;
              }
            }
          }
        } else if (
          data.dataKPIs[i].date <= todayTimestamp[data.networkSelectedId] &&
          getDifferenceInDays(
            todayTimestamp[data.networkSelectedId],
            data.dataKPIs[i].date <= 60,
          )
        ) {
          // if in public user analytics page
          if (viewType === ViewTypes.PUBLIC) {
            olderKPIsInitial.push(data.dataKPIs[i]);
          } else {
            // search selected social network in day
            for (const kpiKey in data.dataKPIs[i]) {
              if (
                kpiKey.substring(0, data.networkSelectedId.length) ===
                  data.networkSelectedId &&
                kpiKey[data.networkSelectedId.length] === '.'
              ) {
                hasNetwork = true;
                olderKPIsInitial.push(data.dataKPIs[i]);
              }

              if (hasNetwork) {
                break;
              }
            }
          }
        }
      }
    }
  }
  for (let i = filteredKPIsInitial.length - 1; i >= 0; i--) {
    filteredKPIsFinal.push(filteredKPIsInitial[i]);
  }
  for (let i = olderKPIsInitial.length - 1; i >= 0; i--) {
    olderKPIsFinal.push(olderKPIsInitial[i]);
  }
  data.setFilteredKPIs(filteredKPIsFinal);
  data.setOlderKpis(olderKPIsFinal);
};

const PublicUserMediaKit: React.FC<IPublicUserMediaKitProps> = (
  props: IPublicUserMediaKitProps,
) => {
  const searchParams = useParams();
  const [mostRecentDayWithData, setMostRecentDayWithData] = useState<number>();
  const [olderKpis, setOlderKpis] = useState([]);
  const [filteredKPIs, setFilteredKPIs] = useState([]);
  const [isGetPublicUserByIdEnabled, setIsGetPublicUserByIdEnabled] =
    useState(false);
  const [isGetSelfUserEnabled, setIsGetSelfUserEnabled] = useState(false);
  const [
    isGetPublicUserHashtagsLatestEnabled,
    setIsGetPublicUserHashtagsLatestEnabled,
  ] = useState(false);
  const [isGetPublicUserKpisOverviewEnabled, setIsGetKpisOverviewEnabled] =
    useState(false);
  const [
    isGetPublicUserKpisOverviewDailyEnabled,
    setIsGetKpisOverviewDailyEnabled,
  ] = useState(false);
  const [
    isGetPublicUserAudienceDistributionEnabled,
    setIsGetPublicUserAudienceDistributionEnabled,
  ] = useState(false);
  const [
    isGetPublicPostsByPublicUserIdEnabled,
    setIsGetPublicPostsByPublicUserIdEnabled,
  ] = useState(false);
  const [
    isProcessingPublicUserKpisDailyData,
    setIsProcessingPublicUserKpisDailyData,
  ] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [msgError, setMsgError] = useState('');
  const {
    data: publicUsers,
    isFetching: isFetchingGetPublicUser,
    isSuccess: isSuccessGetPublicUser,
    isError: isErrorGetPublicUser,
  } = useGetPublicUserById(isGetPublicUserByIdEnabled, [
    searchParams.publicUserId!,
  ]);
  const {
    data: selfUser,
    isFetching: isFetchingGetSelfUser,
    isSuccess: isSuccessGetSelfUser,
    isError: isErrorGetSelfUser,
  } = useGetSelfUser(isGetSelfUserEnabled);
  const {
    data: publicUserHashtagsLatest,
    isFetching: isFetchingGetPublicUserHashtagsLatest,
    isSuccess: isSuccessGetPublicUserHashtagsLatest,
    isError: isErrorGetPublicUserHashtagsLatest,
  } = useGetPublicUserHashtagsLatest(isGetPublicUserHashtagsLatestEnabled, {
    publicUserId: searchParams.publicUserId!,
    startDate: START_DATE_TIMESTAMP,
    endDate: END_DATE_TIMESTAMP,
  });
  const {
    data: publicUserKpisOverview,
    isFetching: isFetchingGetPublicUserKpisOverview,
    isSuccess: isSuccessGetPublicUserKpisOverview,
    isError: isErrorGetPublicUserKpisOverview,
  } = useGetPublicUserKpisOverview(isGetPublicUserKpisOverviewEnabled, {
    publicUserIds: [searchParams.publicUserId!],
    startDate: START_DATE_TIMESTAMP,
    endDate: END_DATE_TIMESTAMP,
  });
  const {
    data: publicUserKpisOverviewDaily,
    isFetching: isFetchingGetPublicUserKpisOverviewDaily,
    isSuccess: isSuccessGetPublicUserKpisOverviewDaily,
    isError: isErrorGetPublicUserKpisOverviewDaily,
  } = useGetKpisOverviewDaily(isGetPublicUserKpisOverviewDailyEnabled, {
    publicUserIds: [searchParams.publicUserId!],
    startDate: START_DATE_TIMESTAMP,
    endDate: END_DATE_TIMESTAMP,
    socialNetwork: publicUsers?.[0]?.socialNetwork.network,
  });
  const {
    data: publicUserAudienceDistributionData,
    isFetching: isFetchingGetPublicUserAudienceDistributionData,
    isSuccess: isSuccessGetPublicUserAudienceDistributionData,
    isError: isErrorGetPublicUserAudienceDistributionData,
  } = useGetPublicUserAudienceDistributionData(
    isGetPublicUserAudienceDistributionEnabled,
    {
      publicUserIds: [searchParams.publicUserId!],
      startDate: START_DATE_TIMESTAMP,
      endDate: END_DATE_TIMESTAMP,
    },
  );
  const {
    data: publicPosts,
    isFetching: isFetchingGetPublicPostsByPublicUserId,
    isSuccess: isSuccessGetPublicPostsByPublicUserId,
    isError: isErrorGetPublicPostsByPublicUserId,
  } = useGetPublicPostsByPublicUserId(isGetPublicPostsByPublicUserIdEnabled, {
    publicUserIds: [searchParams.publicUserId!],
    startDate: START_DATE_TIMESTAMP,
    endDate: END_DATE_TIMESTAMP,
  });

  useEffect(() => {
    if (searchParams.publicUserId) {
      setIsGetPublicUserHashtagsLatestEnabled(true);
      setIsGetKpisOverviewEnabled(true);
      setIsGetPublicUserByIdEnabled(true);
      setIsGetPublicUserAudienceDistributionEnabled(true);
      setIsGetPublicPostsByPublicUserIdEnabled(true);
      setIsGetSelfUserEnabled(true);
    }
  }, [searchParams.publicUserId]);

  useEffect(() => {
    if (isSuccessGetPublicUser && searchParams.publicUserId) {
      setIsGetKpisOverviewDailyEnabled(true);
    }
  }, [isSuccessGetPublicUser, searchParams.publicUserId]);

  useEffect(() => {
    if (isSuccessGetPublicUserKpisOverviewDaily) {
      setIsProcessingPublicUserKpisDailyData(true);

      let orderedKPIs = null;

      orderedKPIs = orderKPIs(publicUserKpisOverviewDaily.dataKPIs);

      for (let i = orderedKPIs.length - 1; i >= 0; i--) {
        if (
          orderedKPIs[i].networks.indexOf(
            publicUsers?.[0]?.socialNetwork.network,
          ) < 0
        ) {
          continue;
        }
        setMostRecentDayWithData(orderedKPIs[i].date);
        break;
      }

      filterKPIs(
        {
          dataKPIs: publicUserKpisOverviewDaily.dataKPIs,
          date: [
            {
              startDate: publicUserKpisOverviewDaily.date[0].startDate,
              endDate: publicUserKpisOverviewDaily.date[0].endDate,
            },
          ],
          networkSelectedId: publicUsers?.[0]?.socialNetwork.network,
          setMostRecentDayWithData,
          setFilteredKPIs,
          setOlderKpis,
        },
        ViewTypes.PUBLIC,
      );

      setIsProcessingPublicUserKpisDailyData(false);
    }
  }, [isSuccessGetPublicUserKpisOverviewDaily, searchParams.publicUserId]);

  useEffect(() => {
    if (
      isErrorGetPublicUser ||
      isErrorGetSelfUser ||
      isErrorGetPublicUserHashtagsLatest ||
      isErrorGetPublicUserKpisOverview ||
      isErrorGetPublicUserKpisOverviewDaily ||
      isErrorGetPublicUserAudienceDistributionData ||
      isErrorGetPublicPostsByPublicUserId
    ) {
      setHasError(true);
      setMsgError(props.t('mediaKit.error.error-getting-data'));
    }
  }, [
    isErrorGetPublicUser,
    isErrorGetSelfUser,
    isErrorGetPublicUserHashtagsLatest,
    isErrorGetPublicUserKpisOverview,
    isErrorGetPublicUserKpisOverviewDaily,
    isErrorGetPublicUserAudienceDistributionData,
    isErrorGetPublicPostsByPublicUserId,
  ]);

  const getLoaderMessage = () => {
    if (
      isFetchingGetPublicUser ||
      isFetchingGetSelfUser ||
      isFetchingGetPublicUserHashtagsLatest ||
      isFetchingGetPublicUserKpisOverview ||
      isFetchingGetPublicUserKpisOverviewDaily ||
      isProcessingPublicUserKpisDailyData ||
      isFetchingGetPublicUserAudienceDistributionData ||
      isFetchingGetPublicPostsByPublicUserId
    ) {
      return props.t('mediaKit.loading_media_kit_data');
    }
    return '';
  };

  const canRenderSection = (section: MediaKitSections) => {
    switch (section) {
      case MediaKitSections.USER_PRESENTATIONAL_CARD: {
        if (
          isSuccessGetPublicUser &&
          isSuccessGetSelfUser &&
          isSuccessGetPublicUserHashtagsLatest &&
          isSuccessGetPublicUserKpisOverview &&
          publicUsers[0]
        ) {
          return true;
        }
        return false;
      }
      case MediaKitSections.EVOLUTION_PERFORMANCE: {
        if (isSuccessGetPublicUserKpisOverviewDaily && publicUsers?.[0]) {
          return true;
        }
        return false;
      }
      case MediaKitSections.AUDIENCE_DEMOGRAPHICS: {
        if (
          isSuccessGetPublicUserAudienceDistributionData &&
          publicUserAudienceDistributionData.publicUserAudienceGeo
        ) {
          return true;
        }
        return false;
      }
      case MediaKitSections.POSTS: {
        if (
          isSuccessGetPublicPostsByPublicUserId &&
          publicPosts &&
          publicUsers?.[0]
        ) {
          return true;
        }
        return false;
      }
    }
  };

  return (
    <S.Container>
      <Loader
        show={
          isFetchingGetPublicUser ||
          isFetchingGetSelfUser ||
          isFetchingGetPublicUserHashtagsLatest ||
          isFetchingGetPublicUserKpisOverview ||
          isFetchingGetPublicUserKpisOverviewDaily ||
          isProcessingPublicUserKpisDailyData ||
          isFetchingGetPublicUserAudienceDistributionData ||
          isFetchingGetPublicPostsByPublicUserId
        }
        message={getLoaderMessage()}
      />

      {canRenderSection(MediaKitSections.USER_PRESENTATIONAL_CARD) && (
        <UserPresentationalCard
          publicUser={publicUsers![0]}
          user={selfUser!}
          publicUserHashtagsLatest={publicUserHashtagsLatest ?? []}
          kpis={{
            followers: publicUserKpisOverview?.total.followers ?? 0,
            postCount: publicUserKpisOverview?.total.posts ?? 0,
            likesAvg: publicUserKpisOverview?.avg.likes ?? 0,
            commentsAvg: publicUserKpisOverview?.avg.comments ?? 0,
            engRateAvg: publicUserKpisOverview?.avg.engRate ?? 0,
          }}
          startDate={new Date(START_DATE_TIMESTAMP)}
          endDate={new Date(END_DATE_TIMESTAMP)}
          keywordFilters={[]}
          hasPostPicking={false}
        />
      )}

      {canRenderSection(MediaKitSections.EVOLUTION_PERFORMANCE) && (
        <EvolutionPerformance
          data={{
            filteredKPIs,
            olderKpis,
            networkSelectedId:
              publicUsers![0].socialNetwork.network === SocialNetworks.YOUTUBE
                ? SocialNetworks.YOUTUBE
                : SocialNetworks.FACEBOOKPAGEINSTAGRAM,
            date: publicUserKpisOverviewDaily!.date,
            kpisOverview: publicUserKpisOverview,
          }}
        />
      )}

      {canRenderSection(MediaKitSections.AUDIENCE_DEMOGRAPHICS) && (
        <AudienceDistribution
          age={publicUserAudienceDistributionData.publicUserAudienceAgeGroups}
          city={
            publicUserAudienceDistributionData.publicUserAudienceGeo!.cities
          }
          country={
            publicUserAudienceDistributionData.publicUserAudienceGeo!.countries
          }
          gender={publicUserAudienceDistributionData.publicUserAudienceGenders}
          type={publicUserAudienceDistributionData.publicUserAudienceTypes}
          reachability={
            publicUserAudienceDistributionData.publicUserAudienceReachability
          }
        />
      )}

      {canRenderSection(MediaKitSections.POSTS) && (
        <Posts posts={publicPosts!} user={publicUsers![0]} />
      )}

      {(canRenderSection(MediaKitSections.USER_PRESENTATIONAL_CARD) ||
        canRenderSection(MediaKitSections.EVOLUTION_PERFORMANCE) ||
        canRenderSection(MediaKitSections.AUDIENCE_DEMOGRAPHICS) ||
        canRenderSection(MediaKitSections.POSTS)) && (
        <S.PoweredByOuterContainer>
          <S.PoweredByContainer>
            <S.PoweredByInnerContainer>
              <S.PoweredByLabel
                className="madeby-button"
                onClick={() => window.open('https://skorr.social/', '_blank')}
              >
                {props.t(`mediaKit.powered-by-label`)}
                <S.PoweredByImage
                  src={require('../../assets/mk-copyright-logo.png')}
                />
              </S.PoweredByLabel>
            </S.PoweredByInnerContainer>
          </S.PoweredByContainer>
        </S.PoweredByOuterContainer>
      )}

      <GenericModal
        show={hasError}
        title={props.t('generic.uhoh')}
        body={msgError}
        primaryButtonText={props.t('generic.close')}
        primaryButtonAction={() => setHasError(false)}
      />
    </S.Container>
  );
};

export default withTranslation()(PublicUserMediaKit);
