/* eslint-disable import/extensions */
import styled from 'styled-components';

import SVG from './Styles';

interface IEditIconProps {
  containerWidth?: string;
  containerHeight?: string;
  containerPaddingTop?: string;
  containerPaddingBottom?: string;
  containerPaddingLeft?: string;
  containerPaddingRight?: string;
  containerMarginTop?: string;
  containerMarginBottom?: string;
  containerMarginLeft?: string;
  containerMarginRight?: string;
  svgWidth?: string;
  svgHeight?: string;
  cursor?: string;
  onClick?: any;
  id?: string;
}

interface IContainerProps {
  width?: string;
  height?: string;
  paddingTop?: string;
  paddingBottom?: string;
  paddingLeft?: string;
  paddingRight?: string;
  marginTop?: string;
  marginBottom?: string;
  marginLeft?: string;
  marginRight?: string;
}

const Container = styled.div<IContainerProps>`
  border-width: 0;
  outline: 0;
  width: ${props => (props.width ? props.width : 'auto')};
  height: ${props => (props.height ? props.height : 'auto')};
  padding-top: ${props => (props.paddingTop ? props.paddingTop : '0')};
  padding-bottom: ${props => (props.paddingBottom ? props.paddingBottom : '0')};
  padding-left: ${props => (props.paddingLeft ? props.paddingLeft : '0')};
  padding-right: ${props => (props.paddingRight ? props.paddingRight : '0')};
  margin-top: ${props => (props.marginTop ? props.marginTop : '0')};
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : '0')};
  margin-left: ${props => (props.marginLeft ? props.marginLeft : '0')};
  margin-right: ${props => (props.marginRight ? props.marginRight : '0')};
  display: inline-flex;
  align-items: center;
`;

const EditIcon = (props: IEditIconProps) => {
  return (
    <Container
      width={props.containerWidth}
      height={props.containerHeight}
      paddingTop={props.containerPaddingTop}
      paddingBottom={props.containerPaddingBottom}
      paddingLeft={props.containerPaddingLeft}
      paddingRight={props.containerPaddingRight}
      marginTop={props.containerMarginTop}
      marginBottom={props.containerMarginBottom}
      marginLeft={props.containerMarginLeft}
      marginRight={props.containerMarginRight}
      id={props.id}
    >
      <SVG
        viewBox="0 0 32 32"
        width={props.svgWidth ? props.svgWidth : '100%'}
        height={props.svgHeight ? props.svgHeight : '100%'}
        cursor={props.cursor}
      >
        <path
          fill="#8494A9"
          d="M21.179 4.946c0.821-0.821 1.879-1.232 2.937-1.232s2.116 0.411 2.937 1.232c1.642 1.642 1.642 4.232 0 5.874l-15.98 15.98-0.316 0.063-5.558 1.105-1.484 0.316 0.316-1.484 1.105-5.558 0.063-0.316 15.98-15.98zM25.632 6.368c-0.494-0.494-1.007-0.726-1.516-0.726s-1.022 0.233-1.516 0.726l-0.695 0.726 3 3 0.726-0.695c0.983-0.983 0.983-2.049 0-3.032zM20.485 8.515l-12.222 12.222c1.295 0.663 2.337 1.705 3 3l12.222-12.222-3-3zM9.62 25.032c-0.489-1.212-1.441-2.163-2.653-2.653l-0.663 3.316 3.316-0.663z"
        />
      </SVG>
    </Container>
  );
};

export default EditIcon;
